import { ErrorMessage } from '@hookform/error-message';
import React, { useCallback } from 'react';
import { useForm } from 'react-hook-form';
import useCreateUser from '../../modules/user/hooks/use-create-user';
import PrettyButton from '../pretty-button';
import './style.scss'

type Props = {}

const LoginForm: React.FC<Props> = () => {

    const { register, handleSubmit, formState } = useForm();
    const createUser = useCreateUser();

    const onSubmit = useCallback((data) => {
        createUser(data.name);
    }, [createUser]);

    return <div className="login-form">
        <form onSubmit={handleSubmit(onSubmit)}>

            <label>
                <input type="text" id="" {...register('name', { required: { value: true, message: 'Proszę podaj imię' } })} /><br />
                <span className="error">
                    <ErrorMessage errors={formState.errors} name="name" />
                </span>
            </label>

            <div className="buttons">
                <PrettyButton type="submit">
                    Zaczynamy
                </PrettyButton>

            </div>

        </form>
    </div>
}

export default LoginForm;
