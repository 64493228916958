import React, { createRef, useCallback, useEffect, useMemo, useReducer, useRef, useState } from "react"
import DropZone from "../../../drag-drop-template/drop-zone";

import krolImg from '../../../../assets/task-07/krol.png';
import magistratImg from '../../../../assets/task-07/magistratury.png';
import sejmImg from '../../../../assets/task-07/sejm.png';

import item1 from '../../../../assets/task-07/item-1.png';
import item2 from '../../../../assets/task-07/item-2.png';
import item3 from '../../../../assets/task-07/item-3.png';


//@ts-ignore
import { Preview } from 'react-dnd-preview'
import PickUp from "../../../drag-drop-template/pick-up";

type Props = {
    onComplete: () => void
}

type TextItem = {
    type: 'text'
    id: string,
    text: string
}

type ImageItem = {
    type: 'image'
    id: string,
    image: string
}

type Bracket = {
    id: string,
    image: string,
    text: string,
    correct: string[],
    textItem?: string,
    imageItem?: string,
    isCorrect?: boolean
}

type State = {
    brackets: Bracket[],
    textItems: TextItem[],
    imageItems: ImageItem[]
}

const Task07Game: React.FC<Props> = ({ onComplete }) => {

    const [state, remove] = useReducer((state: State, action: { itemId: string, bracketId: string }) => {

        const item = [
            ...state.textItems,
            ...state.imageItems
        ].find(i => i.id === action.itemId);

        if (!item) return state;

        const result = {
            ...state,
            brackets: state.brackets.map(b => {
                if (b.id === action.bracketId) {
                    return {
                        ...b,
                        isCorrect: true,
                        textItem: item.type === 'text' ? item.id : b.textItem,
                        imageItem: item.type === 'image' ? item.id : b.imageItem,
                    }
                }

                return b
            }),
            textItems: state.textItems.filter(i => i.id !== action.itemId),
            imageItems: state.imageItems.filter(i => i.id !== action.itemId)
        }

        return result;
    }, {
        brackets: [
            {
                id: 'sejm', image: sejmImg, correct: ['img1', 't2'],
                text: 'Prawodawstwo sprawowane być nie może przez wszystkich i naród wyręcza się w tej mierze przez reprezentantów czyli posłów swoich dobrowolnie wybranych, przeto stanowimy, iż posłowie na sejmikach obrani w prawodawstwie i ogólnych narodu potrzebach podług niniejszej konstytucji uważani być mają jako reprezentanci całego narodu, będąc składem ufności powszechnej. '
            },
            {
                id: 'magistrat', image: magistratImg, correct: ['img3', 't3'],
                text: 'Powinna zaś być tak do miejsc przywiązaną, żeby każdy człowiek bliską dla siebie znalazł sprawiedliwość, żeby przestępny widział wszędzie groźną nad sobą rękę krajowego rządu.'
            },
            {
                id: 'krol', image: krolImg, correct: ['img2', 't1'],
                text: 'nie będzie mogła praw stanowić ani tłumaczyć, podatków i poborów pod jakimkolwiek imieniem nakładać, długów publicznych zaciągać, rozkładu dochodów skarbowych przez sejm zrobionego odmieniać, wojny wydawać, pokoju ani traktatu i żadnego aktu dyplomatycznego definitive zawierać. Wolno jej tylko będzie tymczasowe z zagranicznemi prowadzić negocjacje oraz tymczasowe i potoczne dla bezpieczeństwa i spokojności kraju wynikające potrzeby załatwiać'
            }
        ],
        textItems: [
            { type: 'text', id: 't1', text: 'Król - władza wykonawcza' },
            { type: 'text', id: 't2', text: 'Sejm - władza prawodawcza' },
            { type: 'text', id: 't3', text: 'Magistratury - władza sądownicza' }
        ],
        imageItems: [
            { type: 'image', id: "img1", image: item1 },
            { type: 'image', id: "img2", image: item2 },
            { type: 'image', id: "img3", image: item3 }
        ]
    })

    const activeBracket = useMemo(() => {
        return state.brackets.find(b => (!b.imageItem || !b.textItem));
    }, [state]);

    const [bracketState, setBracketState] = useState<'' | 'wrong' | 'correct'>('');

    const [bRefs, setBRefs] = useState<React.RefObject<HTMLDivElement>[]>([]);
    const [bracketsHeight, setBracketsHeight] = useState(0);

    useEffect(() => {
        if (bracketState) {
            const id = setTimeout(() => {
                setBracketState('')
            }, 1000);

            return () => {
                clearTimeout(id);
            }
        }
    }, [bracketState, setBracketState])

    const onDrop = useCallback((pickupData: string, dropId: string) => {

        const bracket = state.brackets.find(b => b.id === dropId);

        if (bracket && bracket.correct.includes(pickupData)) {
            setBracketState('correct');
            remove({
                itemId: pickupData,
                bracketId: dropId
            });
        } else {
            setBracketState('wrong')
        }

    }, [state, remove]);

    useEffect(() => {
        if (state.imageItems.length === 0 && state.textItems.length === 0) {
            onComplete();
            setBracketsHeight(0);
        }
    }, [state, onComplete, setBracketsHeight]);

    useEffect(() => {
        setBRefs(
            Array(3).fill(null).map((_, i) => createRef<HTMLDivElement>())
        )
    }, [setBRefs]);

    const onResize = useCallback(() => {
        const maxHeight = bRefs.reduce((prev: number, next) => {

            if (next.current) {
                const h = next.current?.getBoundingClientRect().height;

                if (h > prev) {
                    return h;
                }
            }

            return prev;
        }, 0);

        setBracketsHeight(maxHeight);

    }, [bRefs, setBracketsHeight]);

    useEffect(() => {
        window.addEventListener('resize', onResize);
        onResize();

        return () => {
            onResize();
        }
    }, [onResize]);

    return <div className="task-07-game">
        <div className={`bracket-container ${bracketState} ${activeBracket ? '' : 'hide'}`}>
            <div className={`brackets`} style={{
                height: `${bracketsHeight}px`
            }}>
                {
                    state.brackets.map((bracket, index) => {
                        return <div key={bracket.id} className={`bracket ${bracket.id === activeBracket?.id ? 'active' : ''}`} style={{
                            height: `${bracketsHeight}px`
                        }}>
                            <DropZone id={bracket.id} targetType="item" onDrop={onDrop}>
                                <p ref={bRefs[index]}>{bracket.text}</p>
                            </DropZone>
                        </div>
                    })
                }
            </div>
        </div>
        {<div className="image-items">
            {
                state.imageItems.map(item => {
                    return <div className="item-in-pool" key={item.id}>
                        <PickUp data={{ type: 'item', id: item.id, data: item }}>
                            <GameImageItem item={item} />
                        </PickUp>
                    </div>
                })
            }
            {
                Array(3 - state.imageItems.length).fill(null).map((_, index) => {
                    return <div className="item-in-pool" key={index}></div>
                })
            }
        </div>}
        {<div className="text-items">
            {
                state.textItems.map(item => {
                    return <div className="item-in-pool" key={item.id}>
                        <PickUp data={{ type: 'item', id: item.id, data: item }}>
                            <GameTextItem item={item} />
                        </PickUp>
                    </div>
                })
            }
            {
                Array(3 - state.textItems.length).fill('').map((_, index) => {
                    return <div className="item-in-pool" key={index}></div>
                })
            }
        </div>}
        <div className="dev">
            <ItemPreview />

        </div>
    </div>
}

const GameImageItem: React.FC<{ item: ImageItem }> = ({ item }) => {
    return <div className="task-07-pick-up-image">
        <img src={item.image} alt="" />
    </div>
}

const GameTextItem: React.FC<{ item: TextItem }> = ({ item }) => {
    return <div className="task-07-pick-up-text">
        <div className="container">
            <div className="inner">
                {item.text}
            </div>
        </div>
    </div>
}

const ItemPreview: React.FC<{}> = () => {
    return <Preview>{({ item, style }: any) => {

        if (item.data.type === 'image') {
            return <div style={{ ...style, width: `${item.size.width}px` }}>
                <GameImageItem item={item.data} />
            </div>
        }

        if (item.data.type === 'text') {
            return <div style={{ ...style, width: item.size.width }}>
                <GameTextItem item={item.data} />
            </div>
        }

        return <></>


    }}</Preview>
}


export default Task07Game;
