import React from "react";
import './style.scss';

export default function PreEvent() {
    return <div className="page">
        <div className="pre-event-page">
            <h1>Witajcie</h1>
            <div className="text">
                <p>Strona ruszy<br /><strong>3 maja o  8:00</strong></p>

            </div>
        </div>
    </div>
}
