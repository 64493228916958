import { CrosswordField } from ".";

const crossword: CrosswordField[] = [

    {
        type: 'input',
        position: {x: 8, y: 0},
        correct: 's',
        chain: [{id: 'sasi', index: 0}],
        pencil: '1',
        marked: true
    },
    {
        type: 'input',
        position: {x: 9, y: 0},
        correct: 'a',
        chain: [{id: 'sasi', index: 1}]
    },
    {
        type: 'input',
        position: {x: 10, y: 0},
        correct: 's',
        chain: [{id: 'sasi', index: 2}]
    },
    {
        type: 'input',
        position: {x: 11, y: 0},
        correct: 'i',
        chain: [{id: 'sasi', index: 3}]
    },

    //================================================

    {
        type: 'input',
        position: {x: 0, y: 1},
        correct: 'w',
        chain: [{id: 'wolna', index: 0}],
        pencil: '2'
    },
    {
        type: 'input',
        position: {x: 1, y: 1},
        correct: 'o',
        chain: [{id: 'wolna', index: 1}]
    },
    {
        type: 'input',
        position: {x: 2, y: 1},
        correct: 'l',
        chain: [{id: 'wolna', index: 2}]
    },
    {
        type: 'input',
        position: {x: 3, y: 1},
        correct: 'n',
        chain: [{id: 'wolna', index: 3}]
    },
    {
        type: 'input',
        position: {x: 4, y: 1},
        correct: 'a',
        chain: [{id: 'wolna', index: 4}]
    },
    {
        type: 'label',
        position: {x: 5, y: 1},
        text: ' '
    },
    // {
    //     type: 'input',
    //     position: {x: 5, y: 1},
    //     correct: ' ',
    //     chain: [{id: 'wolna', index: 5}]
    // },
    {
        type: 'input',
        position: {x: 6, y: 1},
        correct: 'e',
        chain: [{id: 'wolna', index: 6}]
    },
    {
        type: 'input',
        position: {x: 7, y: 1},
        correct: 'l',
        chain: [{id: 'wolna', index: 7}]
    },
    {
        type: 'input',
        position: {x: 8, y: 1},
        correct: 'e',
        chain: [{id: 'wolna', index: 8}],
        marked: true
    },
    {
        type: 'input',
        position: {x: 9, y: 1},
        correct: 'k',
        chain: [{id: 'wolna', index: 9}]
    },
    {
        type: 'input',
        position: {x: 10, y: 1},
        correct: 'c',
        chain: [{id: 'wolna', index: 10}]
    },
    {
        type: 'input',
        position: {x: 11, y: 1},
        correct: 'j',
        chain: [{id: 'wolna', index: 11}]
    },
    {
        type: 'input',
        position: {x: 12, y: 1},
        correct: 'a',
        chain: [{id: 'wolna', index: 12}]
    },

    //=================================================

    {
        type: 'input',
        position: {x: 6, y: 2},
        correct: 's',
        chain: [{id: 'sejm', index: 0}],
        pencil: '3'
    },
    {
        type: 'input',
        position: {x: 7, y: 2},
        correct: 'e',
        chain: [{id: 'sejm', index: 1}]
    },
    {
        type: 'input',
        position: {x: 8, y: 2},
        correct: 'j',
        chain: [{id: 'sejm', index: 2}],
        marked: true
    },
    {
        type: 'input',
        position: {x: 9, y: 2},
        correct: 'm',
        chain: [{id: 'sejm', index: 3}]
    },

    //===================================================

    {
        type: 'input',
        position: {x: 4, y: 3},
        correct: 'p',
        chain: [{id: 'preambula', index: 0}],
        pencil: '4'
    },
    {
        type: 'input',
        position: {x: 5, y: 3},
        correct: 'r',
        chain: [{id: 'preambula', index: 1}]
    },
    {
        type: 'input',
        position: {x: 6, y: 3},
        correct: 'e',
        chain: [{id: 'preambula', index: 2}]
    },
    {
        type: 'input',
        position: {x: 7, y: 3},
        correct: 'a',
        chain: [{id: 'preambula', index: 3}]
    },
    {
        type: 'input',
        position: {x: 8, y: 3},
        correct: 'm',
        chain: [{id: 'preambula', index: 4}],
        marked: true
    },
    {
        type: 'input',
        position: {x: 9, y: 3},
        correct: 'b',
        chain: [{id: 'preambula', index: 5}]
    },
    {
        type: 'input',
        position: {x: 10, y: 3},
        correct: 'u',
        chain: [{id: 'preambula', index: 6}]
    },
    {
        type: 'input',
        position: {x: 11, y: 3},
        correct: 'ł',
        chain: [{id: 'preambula', index: 7}]
    },
    {
        type: 'input',
        position: {x: 12, y: 3},
        correct: 'a',
        chain: [{id: 'preambula', index: 8}]
    },

    //========================================================

    {
        type: 'input',
        position: {x: 1, y: 4},
        correct: 'l',
        chain: [{id: 'veto', index: 1}],
        pencil: '5'
    },
    {
        type: 'input',
        position: {x: 2, y: 4},
        correct: 'i',
        chain: [{id: 'veto', index: 2}]
    },
    {
        type: 'input',
        position: {x: 3, y: 4},
        correct: 'b',
        chain: [{id: 'veto', index: 3}]
    },
    {
        type: 'input',
        position: {x: 4, y: 4},
        correct: 'e',
        chain: [{id: 'veto', index: 4}]
    },
    {
        type: 'input',
        position: {x: 5, y: 4},
        correct: 'r',
        chain: [{id: 'veto', index: 5}]
    },
    {
        type: 'input',
        position: {x: 6, y: 4},
        correct: 'u',
        chain: [{id: 'veto', index: 6}]
    },
    {
        type: 'input',
        position: {x: 7, y: 4},
        correct: 'm',
        chain: [{id: 'veto', index: 7}]
    },
    {
        type: 'label',
        position: {x: 8, y: 4},
        text: ' '
    },
    // {
    //     type: 'input',
    //     position: {x: 8, y: 4},
    //     correct: ' ',
    //     chain: [{id: 'veto', index: 8}],
    //     marked: true
    // },
    {
        type: 'input',
        position: {x: 9, y: 4},
        correct: 'v',
        chain: [{id: 'veto', index: 9}]
    },
    {
        type: 'input',
        position: {x: 10, y: 4},
        correct: 'e',
        chain: [{id: 'veto', index: 10}]
    },
    {
        type: 'input',
        position: {x: 11, y: 4},
        correct: 't',
        chain: [{id: 'veto', index: 11}]
    },
    {
        type: 'input',
        position: {x: 12, y: 4},
        correct: 'o',
        chain: [{id: 'veto', index: 12}]
    },

    //=================================================================

    {
        type: 'input',
        position: {x: 2, y: 5},
        correct: 'w',
        chain: [{id: 'wykonawcza', index: 2}],
        pencil: '6'
    },
    {
        type: 'input',
        position: {x: 3, y: 5},
        correct: 'y',
        chain: [{id: 'wykonawcza', index: 3}]
    },
    {
        type: 'input',
        position: {x: 4, y: 5},
        correct: 'k',
        chain: [{id: 'wykonawcza', index: 4}]
    },
    {
        type: 'input',
        position: {x: 5, y: 5},
        correct: 'o',
        chain: [{id: 'wykonawcza', index: 5}]
    },
    {
        type: 'input',
        position: {x: 6, y: 5},
        correct: 'n',
        chain: [{id: 'wykonawcza', index: 6}]
    },
    {
        type: 'input',
        position: {x: 7, y: 5},
        correct: 'a',
        chain: [{id: 'wykonawcza', index: 7}]
    },
    {
        type: 'input',
        position: {x: 8, y: 5},
        correct: 'w',
        chain: [{id: 'wykonawcza', index: 8}],
        marked: true
    },
    {
        type: 'input',
        position: {x: 9, y: 5},
        correct: 'c',
        chain: [{id: 'wykonawcza', index: 9}]
    },
    {
        type: 'input',
        position: {x: 10, y: 5},
        correct: 'z',
        chain: [{id: 'wykonawcza', index: 10}]
    },
    {
        type: 'input',
        position: {x: 11, y: 5},
        correct: 'a',
        chain: [{id: 'wykonawcza', index: 11}]
    },

    //======================================================

    {
        type: 'input',
        position: {x: 7, y: 6},
        correct: 'l',
        chain: [{id: 'litwa', index: 0}],
        pencil: '7'
    },
    {
        type: 'input',
        position: {x: 8, y: 6},
        correct: 'i',
        chain: [{id: 'litwa', index: 1}],
        marked: true
    },
    {
        type: 'input',
        position: {x: 9, y: 6},
        correct: 't',
        chain: [{id: 'litwa', index: 2}]
    },
    {
        type: 'input',
        position: {x: 10, y: 6},
        correct: 'w',
        chain: [{id: 'litwa', index: 3}]
    },
    {
        type: 'input',
        position: {x: 11, y: 6},
        correct: 'a',
        chain: [{id: 'litwa', index: 4}]
    },

    //======================================================

    {
        type: 'input',
        position: {x: 1, y: 7},
        correct: 'm',
        chain: [{id: 'marszalek', index: 1}],
        pencil: '8'
    },
    {
        type: 'input',
        position: {x: 2, y: 7},
        correct: 'a',
        chain: [{id: 'marszalek', index: 2}]
    },
    {
        type: 'input',
        position: {x: 3, y: 7},
        correct: 'r',
        chain: [{id: 'marszalek', index: 3}]
    },
    {
        type: 'input',
        position: {x: 4, y: 7},
        correct: 's',
        chain: [{id: 'marszalek', index: 4}]
    },
    {
        type: 'input',
        position: {x: 5, y: 7},
        correct: 'z',
        chain: [{id: 'marszalek', index: 5}]
    },
    {
        type: 'input',
        position: {x: 6, y: 7},
        correct: 'a',
        chain: [{id: 'marszalek', index: 6}]
    },
    {
        type: 'input',
        position: {x: 7, y: 7},
        correct: 'ł',
        chain: [{id: 'marszalek', index: 7}]
    },
    {
        type: 'input',
        position: {x: 8, y: 7},
        correct: 'e',
        chain: [{id: 'marszalek', index: 8}],
        marked: true
    },
    {
        type: 'input',
        position: {x: 9, y: 7},
        correct: 'k',
        chain: [{id: 'marszalek', index: 9}]
    },

    //===============================================================

    {
        type: 'input',
        position: {x: 4, y: 8},
        correct: 'd',
        chain: [{id: 'dwa', index: 4}],
        pencil: '9'
    },
    {
        type: 'input',
        position: {x: 5, y: 8},
        correct: 'w',
        chain: [{id: 'dwa', index: 5}]
    },
    {
        type: 'input',
        position: {x: 6, y: 8},
        correct: 'a',
        chain: [{id: 'dwa', index: 6}]
    },
    {
        type: 'label',
        position: {x: 7, y: 8},
        text: ' '
    },
    // {
    //     type: 'input',
    //     position: {x: 7, y: 8},
    //     correct: ' ',
    //     chain: [{id: 'dwa', index: 7}]
    // },
    {
        type: 'input',
        position: {x: 8, y: 8},
        correct: 'l',
        chain: [{id: 'dwa', index: 8}],
        marked: true
    },
    {
        type: 'input',
        position: {x: 9, y: 8},
        correct: 'a',
        chain: [{id: 'dwa', index: 9}]
    },
    {
        type: 'input',
        position: {x: 10, y: 8},
        correct: 't',
        chain: [{id: 'dwa', index: 10}]
    },
    {
        type: 'input',
        position: {x: 11, y: 8},
        correct: 'a',
        chain: [{id: 'dwa', index: 11}]
    },

    //======================================================================================

    {
        type: 'input',
        position: {x: 3, y: 9},
        correct: 'm',
        chain: [{id: 'matejko', index: 3}],
        pencil: '10'
    },
    {
        type: 'input',
        position: {x: 4, y: 9},
        correct: 'a',
        chain: [{id: 'matejko', index: 4}]
    },
    {
        type: 'input',
        position: {x: 5, y: 9},
        correct: 't',
        chain: [{id: 'matejko', index: 5}]
    },
    {
        type: 'input',
        position: {x: 6, y: 9},
        correct: 'e',
        chain: [{id: 'matejko', index: 6}]
    },
    {
        type: 'input',
        position: {x: 7, y: 9},
        correct: 'j',
        chain: [{id: 'matejko', index: 7}]
    },
    {
        type: 'input',
        position: {x: 8, y: 9},
        correct: 'k',
        chain: [{id: 'matejko', index: 8}],
        marked: true
    },
    {
        type: 'input',
        position: {x: 9, y: 9},
        correct: 'o',
        chain: [{id: 'matejko', index: 9}]
    },

    //====================================================

    {
        type: 'input',
        position: {x: 0, y: 10},
        correct: 'o',
        chain: [{id: 'oswiecenie', index: 0}],
        pencil: '11'
    },
    {
        type: 'input',
        position: {x: 1, y: 10},
        correct: 'ś',
        chain: [{id: 'oswiecenie', index: 1}]
    },
    {
        type: 'input',
        position: {x: 2, y: 10},
        correct: 'w',
        chain: [{id: 'oswiecenie', index: 2}]
    },
    {
        type: 'input',
        position: {x: 3, y: 10},
        correct: 'i',
        chain: [{id: 'oswiecenie', index: 3}]
    },
    {
        type: 'input',
        position: {x: 4, y: 10},
        correct: 'e',
        chain: [{id: 'oswiecenie', index: 4}]
    },
    {
        type: 'input',
        position: {x: 5, y: 10},
        correct: 'c',
        chain: [{id: 'oswiecenie', index: 5}]
    },
    {
        type: 'input',
        position: {x: 6, y: 10},
        correct: 'e',
        chain: [{id: 'oswiecenie', index: 6}]
    },
    {
        type: 'input',
        position: {x: 7, y: 10},
        correct: 'n',
        chain: [{id: 'oswiecenie', index: 7}]
    },
    {
        type: 'input',
        position: {x: 8, y: 10},
        correct: 'i',
        chain: [{id: 'oswiecenie', index: 8}],
        marked: true
    },
    {
        type: 'input',
        position: {x: 9, y: 10},
        correct: 'e',
        chain: [{id: 'oswiecenie', index: 9}]
    },


]

export default crossword;
