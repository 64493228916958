import React, { useRef, useEffect, useState, useCallback, useMemo, useReducer } from "react";
import createJigsaw from "./create";
import image from '../../assets/puzzle.jpg';
import PrettyButton from "../pretty-button";

type Props = {
    onComplete: () => void
}

export type API = {
    movePiece: (id: number, x: number, y: number) => void,
    destroy: () => void
}

export type GameState = {
    width: number,
    height: number,
    image: string,
    pieces: { id: number, x: number, y: number }[]
}

const Jigsaw: React.FunctionComponent<Props> = ({onComplete}) => {
    const widgetContainer = useRef<HTMLDivElement>(null);

    const [gameAPI, setGameAPI] = useState<API>();

    const generateInitState = useCallback(() => {

        const itemsCountX = 4;
        const itemsCountY = 4;

        const BASE_WIDTH = 1778;
        const BASE_HEIGHT = 1103;

        return {
            image,
            width: itemsCountX,
            height: itemsCountY,
            pieces: (new Array(16))
                .fill('')
                .map((_, index) => {
                    return { id: index }
                })
                .sort(() => (Math.random() - .5))
                .map(({ id }, index) => {

                    const x = index % itemsCountX;
                    const y = Math.floor(index / itemsCountX);

                    return {
                        id,
                        x: BASE_WIDTH / (itemsCountX * 2) + x * BASE_WIDTH / (itemsCountX),
                        y: BASE_HEIGHT / (itemsCountY * 2) + y * BASE_HEIGHT / (itemsCountY)
                    }
                })
        }
    }, []);

    const initGameState = useMemo(() => {
        try {
            const state = JSON.parse(window.localStorage.getItem('jigsaw-state') || '');

            if (state) {
                return state
            } else {
                return generateInitState();
            }

        } catch (e) {
            return generateInitState();
        }
    }, [generateInitState]);

    const [gameState, action] = useReducer((state: GameState, action: { type: 'move' | 'reset', payload?: any }) => {

        switch (action.type) {
            case 'move':
                return {
                    ...state,
                    pieces: state.pieces.map(p => {
                        if (p.id === action.payload.id) {
                            return {
                                id: p.id,
                                x: p.x,
                                y: p.y
                            }
                        }

                        return p;
                    })
                }

            case 'reset':
                return generateInitState();
        }

        return state;
    }, initGameState);

    const onPieceMoved = useCallback(() => {

    }, [])

    useEffect(() => {

        gameState.pieces.forEach(p => {
            gameAPI?.movePiece(p.id, p.x, p.y);
        })

        window.localStorage.setItem('jigsaw-state', JSON.stringify(gameState));
    }, [gameState, gameAPI])

    useEffect(() => {
    }, [gameState, onComplete])

    useEffect(() => {
        if (widgetContainer.current && gameState) {
            const params = {
                container: widgetContainer.current,
                onPieceMoved,
                gameState,
                onComplete
            };

            const { movePiece, destroy } = createJigsaw(params);

            setGameAPI({
                movePiece,
                destroy
            });

            return () => {
                destroy();
            }
        }


    }, [widgetContainer, onPieceMoved, gameState, onComplete])

    return <>
        <div ref={widgetContainer} className="gameContainer" style={{ width: '100%', margin: '0 auto', userSelect: 'none' }}></div>

        <PrettyButton onClick={() => { action({ type: 'reset' }) }}>
            Reset
        </PrettyButton>
    </>
}

export default Jigsaw;
