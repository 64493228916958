import { useDrop } from "react-dnd";

type Props = {
    id: string,
    onDrop?: (pickupData: string, dropId: string) => void;
    onOver?: (pickupData: string, dropId: string) => void;
    targetType: string
}

const DropZone: React.FunctionComponent<Props> = ({targetType, id, children, onDrop, onOver }) => {

    const [{isOver}, drop] = useDrop({
        accept: targetType,
        drop: (item) => {
            if (onDrop) {
                //@ts-ignore
                onDrop(item.id, id);
            }
        },
        hover: (item, monitor) => {

            if (monitor.canDrop() && onOver) {
                //@ts-ignore
                onOver(item.id, id);

            }
        },
        collect: (monitor) => {
            return {
                isOver: monitor.isOver() && monitor.canDrop()
            }
        }
    })

    return <div className={`drop-zone ${isOver ? 'is-over' : ''}`} ref={drop}>{children}</div>
}

export default DropZone;
