import React from "react";
import styled from "styled-components";

type Props = {
   onClick?: (e: React.MouseEvent) => void,
   type?: "button" | "submit" | "reset" | undefined,
   theme?: 'true-false' | 'button',
   variant?: string
}

type ContainerProps = {
   theme: 'button' | 'true-false'
}

const Container = styled.div<ContainerProps>`
   display: inline-block;

   background: linear-gradient(
      90deg,
      #ffb302 0%,
      #ffba01 4%,
      #ffba01 18%,
      #ffdf01 35%,
      #ffdf01 62%,
      #fec300 83%,
      #fec300 93%,
      #ffb302 100%
   );

   border-radius: .45em;
   padding: .14em;
   position: relative;

   .inner {
      background: linear-gradient(
         0deg,
         #eb4747 0%,
         #b10d0d 16%,
         #b10d0d 84%,
         #f34f4f 100%
      );
      border-radius: .4em;
      box-shadow: inset 0px 0px 1px 1px #BF2114;

      &:hover {
         background: linear-gradient(
            0deg,
            #b10d0b 0%,
            #e2403e 16%,
            #e2403e 84%,
            #be1b17 100%
         );
      }

      display: flex;
      flex-direction: column;
      align-items: stretch;
   }

   button {
      background: none;
      border: none;
      font-family: 'Alegreya Sans', sans-serif;
      cursor: pointer;

      color: white;
      text-decoration: none;
      text-transform: uppercase;
      font-weight: 500;
      font-size: .75em;
      letter-spacing: .15em;
      display: block;
      line-height: 1.5em;
      text-align: center;
   }

   &.true-false {
      .inner {
         background: linear-gradient(
            0deg,
            #6f7b9d 0%,
            #323e60 16%,
            #323e60 84%,
            #6f7b9d 100%
         );
         box-shadow: inset 0px 0px 1px 1px #3e4862;

         :hover {
            background: linear-gradient(
            0deg,
            #323e60 0%,
            #6f7b9d 16%,
            #6f7b9d 84%,
            #323e60 100%
         );
         }
      }

      &.correct {
         .inner {
            background: linear-gradient(
               0deg,
               #6bce57 0%,
               #4e910f 16%,
               #4e910f 84%,
               #6bce57 100%
            );
            box-shadow: inset 0px 0px 1px 1px #41690e;
         }
      }

      &.incorrect {
         .inner {
            background: linear-gradient(
               0deg,
               #eb4747 0%,
               #b10d0d 16%,
               #b10d0d 84%,
               #f34f4f 100%
            );
            box-shadow: inset 0px 0px 1px 1px #b5150c;
         }
      }

      button {
         line-height: 2em;
      }
   }
`;

const PrettyButton: React.FC<Props> = ({ onClick, children, theme, variant, type }) => {
   return <Container className={`${theme} ${variant}`}>
      <div className="inner">
         <button onClick={onClick} type={type}>{children}</button>
      </div>
   </Container>
}

export default PrettyButton;
