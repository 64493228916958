import React from "react"
import { DndProvider } from "react-dnd"
//@ts-ignore
import MultiBackend, {MouseTransition, TouchTransition} from 'react-dnd-multi-backend'

import { HTML5Backend } from 'react-dnd-html5-backend'
import { TouchBackend } from 'react-dnd-touch-backend'
export const HTML5toTouch = {
    backends: [
      {
        id: 'html5',
        backend: HTML5Backend,
        transition: MouseTransition,
      },
      {
        id: 'touch',
        backend: TouchBackend,
        options: {},
        preview: true,
        transition: TouchTransition,
      },
    ],
  }

const CustomDnDProvider: React.FC<{}> = ({ children }) => {
    return <DndProvider backend={MultiBackend} options={HTML5toTouch}>
        {/* <CustomDragLayer /> */}
        {children}
    </DndProvider>
}


export default CustomDnDProvider;
