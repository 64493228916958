import { useEffect } from "react";

export default function useBodyClass(className: string) {
    useEffect(() => {
        window.document.body.classList.add(className);

        return () => {
            window.document.body.classList.remove(className);
        }
    }, [className])
}
