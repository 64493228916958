import Box from "../../layout/box";
import { getMapRoute } from "../routes";
import './style.scss';
import PrettyLink from "../../components/pretty-link";

export default function RulesPage() {
    return <div className="page rules-page">
        <Box className="rules-box">
            <div className="container">
                <h1>Zasady gry</h1>
                <ol>
                    <li>Na kolejnej stronie wpisz imię lub ksywkę/kryptonim na czas tej gry.</li>
                    <li>Zadania przedstawione są na obrazie Jana Matejki “Konstytucja 3 Maja”. Aby przejść do treści zadania kliknij wybrane złote kółko. Kółka wykonanych zadań znikają.</li>
                    <li>Ukończenie zadania musisz potwierdzić klikając w przycisk "Zadanie wykonane". Aby ukończyć zadania musisz w pełni je wykonać.</li>
                    <li>Aby wrócić później do opisu zasad, kliknij na mapie guzik z napisem: „Instrukcja”.</li>
                </ol>
                <p>
                    Dobrej zabawy!
                </p>
                <div className="buttons">
                    <PrettyLink route={getMapRoute()}>Dalej</PrettyLink>
                </div>

            </div>
        </Box>
    </div>
}
