
import React, { useCallback, useState } from 'react';
import './style.scss';
import Box from '../../../../layout/box';
import TaskHeader from '../task-header';
import TaskButtons from '../task-buttons';
import Task02Game from './game';
import portret from '../../../../assets/task-02/portret.png';

const ID = '02';
const TITLE = 'Stanisław August Poniatowski';
const QUOTE = 'Żaden rząd najdoskonalszy bez dzielnej władzy wykonawczej stać nie może. Szczęśliwość narodów od praw sprawiedliwych, praw skutek od ich wykonania zależy. (...) Każdy król, wstępując na tron, wykona przysięgę Bogu i Narodowi na zachowanie konstytucji niniejszej. (...) Osoba króla jest święta i bezpieczna od wszystkiego. Nic sam przez się nie czyniący, za nic w odpowiedzi narodowi być nie może. Nie samowładcą, ale ojcem i głową narodu być powinien i tym go prawo i konstytucja niniejsza być uznaje i deklaruje. '

type Props = {
    onComplete?: () => void
}

const Task02Widget: React.FC<Props> = ({ onComplete: completeCallback }) => {

    const [completed, setCompleted] = useState(false);
    const onComplete = useCallback(() => {
        setCompleted(true);
    }, [setCompleted]);

    return <div className="task-02">

        <Box>
            <TaskHeader image={portret} title={TITLE} quote={QUOTE} />
            <div className="persona-info">
                <p>
                    <strong>Stanisław August Poniatowski</strong> był ostatnim królem Polski. Przeprowadził szereg reform, wspierał Sejm Czteroletni i powstanie Konstytucji 3 Maja, ale nie zapobiegł rozbiorom i w chwili próby przyłączył się do konfederacji targowickiej, która działała przeciwko Konstytucji.
            </p>
            </div>
        </Box>
        <Box>
            <div className="task-widget">
                <div className="game-intro">
                    <h2>Zadanie</h2>
                    <p>Czy jest w twojej miejscowości ulica 3 Maja? A może znasz jakieś inne obiekty noszące imiona bohaterów tamtego okresu?<br />
                        <strong>Dopasuj obrazki do podpisów i imion.</strong>
                    </p>

                </div>
                <Task02Game onComplete={onComplete} />
                <TaskButtons id={ID} completed={completed} />
            </div>
        </Box>
    </div>
}

export default Task02Widget;
