import { useCallback, useEffect, useReducer, useState } from "react";

import Bowser from "bowser";

const browser = Bowser.getParser(window.navigator.userAgent);
const isSafari = browser.satisfies({
    safari: '>=9'
})

export type ChainInfo = {
    id: string,
    index: number,
}

// Reference for InputEvent: https://w3c.github.io/input-events/#interface-InputEvent
export const IS_INPUT_SUPPORTED = (function () {
    try {
        // just kill browsers off, that throw an error if they don't know
        // `InputEvent`
        const event = new InputEvent('input', {
            data: 'xyz',
            inputType: 'deleteContentForward'
        });
        let support = false;

        // catch the others
        // https://github.com/chromium/chromium/blob/c029168ba251a240b0ec91fa3b4af4214fbbe9ab/third_party/blink/renderer/core/events/input_event.cc#L78-L82
        const el = document.createElement('input');
        el.addEventListener('input', function (e) {
            //@ts-ignore
            if (e.inputType === 'deleteContentForward') {
                support = true;
            }
        });

        el.dispatchEvent(event);
        return support;
    } catch (error) {
        return false;
    }
})();

/**
* A normalized event from InputEvent and KeyboardEvent that works from ie11
* over modern browsers to android browsers (because that beast is worse than ie6)
*/
export interface CompatibleInputEvent {
    data?: string;
    inputType?: string;
    navigationType?: string;
    originalEvent: KeyboardEvent | Event;
}

export const normalizeInputEvent = function (event: KeyboardEvent | Event): CompatibleInputEvent {
    const e: CompatibleInputEvent = {
        originalEvent: event
    };

    if (event instanceof KeyboardEvent) {
        if (event.key === 'Backspace') {
            e.inputType = 'deleteContentBackward';
            e.navigationType = 'cursorLeft';
        } else if (event.key === 'Delete') {
            e.inputType = 'deleteContentForward';
        } else if (event.key.startsWith('Arrow')) {
            e.navigationType = event.key.replace('Arrow', 'cursor');
        } else {
            e.data = event.key;
            e.inputType = 'insertText';
        }
    } else {
        // @ts-ignore event.inputType is there on android - actually what we need here!
        const { inputType } = event;
        e.inputType = inputType;
        //@ts-ignore
        e.data = event.data;

        if (['insertText', 'insertCompositionText'].includes(inputType)) {
            e.navigationType = 'cursorRight';
        }
    }

    return e;
};

export default function useChainInput() {

    const [container, setContainer] = useState<HTMLElement | null>(null);
    const [actChain, setActChain] = useState<string | null>(null);

    const [nodes, addNode] = useReducer((state: HTMLElement[], newNode: HTMLElement) => {

        if (state.includes(newNode)) {
            return state;
        }

        return [...state, newNode];
    }, [])

    const focusNext = useCallback((node: HTMLInputElement) => {
        if (!container) return;
        if (!actChain) return;

        const chainDataStr = node.dataset['chaindata'];
        if (!chainDataStr) return;

        const chainData = JSON.parse(chainDataStr) as ChainInfo[];
        const actChainInfo = chainData.find((c: ChainInfo) => c.id === actChain);

        if (!actChainInfo) return;
        const nodeIndex = actChainInfo.index;

        const nextNode = Array
            .from(container.querySelectorAll('input'))
            .map(node => {
                return {
                    node,
                    chains: JSON.parse(node.dataset?.['chaindata'] || '')
                }
            })
            .filter(({ chains }: { chains: ChainInfo[] | '' }) => {
                return chains && chains.find(c => c.id === actChain);
            })
            .map(({ node, chains }) => {
                return {
                    node,
                    index: (chains as ChainInfo[]).find((c) => c.id === actChain)?.index
                }
            })
            .filter(({ index }) => {
                return (index !== undefined) && index > nodeIndex;
            })
            .map(x => {
                return x;
            })
            .sort((a, b) => ((a.index || 0) - (b.index || 0)))[0]?.node;

        if (nextNode) {
            nextNode.focus();
        }
    }, [container, actChain]);

    const focusPrev = useCallback((node: HTMLInputElement) => {
        if (!container) return;
        if (!actChain) return;

        const chainDataStr = node.dataset['chaindata'];
        if (!chainDataStr) return;

        const chainData = JSON.parse(chainDataStr) as ChainInfo[];
        const actChainInfo = chainData.find((c: ChainInfo) => c.id === actChain);

        if (!actChainInfo) return;
        const nodeIndex = actChainInfo.index;

        const nextNode = Array
            .from(container.querySelectorAll('input'))
            .map(node => {
                return {
                    node,
                    chains: JSON.parse(node.dataset?.['chaindata'] || '')
                }
            })
            .filter(({ chains }: { chains: ChainInfo[] | '' }) => {
                return chains && chains.find(c => c.id === actChain);
            })
            .map(({ node, chains }) => {
                return {
                    node,
                    index: (chains as ChainInfo[]).find((c) => c.id === actChain)?.index
                }
            })
            .filter(({ index }) => {
                return (index !== undefined) && index < nodeIndex;
            })
            .map(x => {
                return x;
            })
            .sort((a, b) => ((b.index || 0) - (a.index || 0)))[0]?.node;

        if (nextNode) {
            nextNode.focus();
        }
    }, [container, actChain]);

    const handleEvent = useCallback((node: HTMLInputElement, e: CompatibleInputEvent) => {

        if (e.navigationType === 'cursorRight') {
            focusNext(node);
        }

        if (e.navigationType === 'cursorLeft') {
            focusPrev(node);
        }

        if (e.inputType === 'deleteContentBackward') {
            node.value = '';
        }

        //@ts-ignore
        if (isSafari && !e.navigationType && e.originalEvent.key.length === 1) {
            setTimeout(() => {
                focusNext(node);

                if (node.value.length) {
                    node.value = node.value[node.value.length - 1];
                }
            }, 100);


        } else {
            if (node.value.length) {
                node.value = node.value[node.value.length - 1];
            }
        }

    }, [focusNext, focusPrev]);

    const onKeyDown = useCallback((event: KeyboardEvent) => {
        const node = event.currentTarget;
        if (node) {
            const e = normalizeInputEvent(event);

            if (isSafari || !IS_INPUT_SUPPORTED || event.key.length > 1) {
                handleEvent(node as HTMLInputElement, e);
            }
        }
    }, [handleEvent]);

    const onInput = useCallback((event: Event) => {
        const node = event.currentTarget as HTMLInputElement;
        if (IS_INPUT_SUPPORTED) {
            handleEvent(node, normalizeInputEvent(event));
        }
    }, [handleEvent]);

    const onFocus = useCallback((event: FocusEvent) => {
        const node = event.currentTarget as HTMLInputElement;
        const chains = node.dataset?.['chaindata'];

        if (node && chains) {
            const chainsIds: string[] = JSON.parse(chains).map((c: { id: string, index: number }) => c.id);

            if (actChain === null || !chainsIds.includes(actChain)) {
                setActChain(chainsIds[0]);
            }
        }

    }, [setActChain, actChain]);

    useEffect(() => {
        nodes.forEach(node => {
            node.addEventListener('keydown', onKeyDown);
            node.addEventListener('focus', onFocus);
            node.addEventListener('input', onInput);
        })

        return () => {
            nodes.forEach(node => {
                node.removeEventListener('keydown', onKeyDown);
                node.removeEventListener('focus', onFocus);
                node.removeEventListener('input', onInput);
            })
        }
    }, [nodes, onKeyDown, onFocus, onInput])

    const registerContainer = useCallback((node: HTMLElement | null) => {
        setContainer(node);
    }, [setContainer])

    return {
        registerInput: (chains: ChainInfo[]) => {

            return (node: HTMLInputElement | null) => {
                if (node !== null) {

                    node.dataset['chaindata'] = JSON.stringify(chains);
                    addNode(node);

                }
                return node;
            };
        },
        registerContainer
    }
}

