
import React, { useState } from 'react';
import { useCallback } from 'react';
import Box from '../../../../layout/box';
import TaskButtons from '../task-buttons';
import TaskHeader from '../task-header';
import './style.scss';
import portret from '../../../../assets/task-09/portret.png';
import Task09Game from './game';

const ID = '09';
const TITLE = 'Stanisław Małachowski';
const QUOTE = 'Przyjęcie prawa miejskiego czynić się ma w następujący sposób; każdy przyjmujący [prawo] miejskie, stanąwszy przed magistratem (...) uczyni oświadczenie w słowach następujących: „Ja, NN, najaśniejszemu królowi i Rzeczypospolitej wiernym będę, posłuszeństwo prawom i ustawom sejmowym za najściślejszy biorąc obowiązek, zwierzchność miasta N (...)". Po dopełnionym takowym oświadczeniu ma być zapisany w księgę obywatelów miasta. (PoM 1).'

type Props = {
    onComplete?: () => void
}

const Task09Widget: React.FC<Props> = ({ onComplete: completeCallback }) => {

    const [completed, setCompleted] = useState(true);
    const onComplete = useCallback((value: boolean) => {
        setCompleted(value);
    }, [setCompleted]);

    return <div className="task-09">
        <Box>
            <TaskHeader image={portret} title={TITLE} quote={QUOTE} />
            <div className="persona-info">
                <p>
                    <strong>Stanisław Małachowski</strong> - był marszałkiem Sejmu Czteroletniego, a w późniejszym czasie prezesem senatu Księstwa Warszawskiego. Był jednym z członków stronnictwa reformatorskiego na Sejmie Wielkim. Dostrzegał konieczność polepszenia sytuacji mieszczan i chłopów oraz głębokiej naprawy stanu państwa, był za ograniczeniem liberum veto oraz władzy hetmanów.
        </p>
            </div>
        </Box>
        <Box>
            <div className="task-widget">
                <div className="game-intro">
                    <h2>Zadanie</h2>
                    <p><strong>Odsłuchaj mazurka Rajnolda Suchodolskiego uzupełnij jego tekst o brakujące słowa:</strong></p>
                </div>
                <div className="video-container">
                    <iframe src="https://www.youtube-nocookie.com/embed/QkopQp9Z5Ic" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen={true}></iframe>
                </div>
                <Task09Game onComplete={onComplete} />
                <TaskButtons id={ID} completed={completed} />
            </div>
        </Box>
    </div>
}

export default Task09Widget;
