import { useCallback, useEffect, useRef } from "react";

type Props = {
    title: string,
    image: string,
    quote: string
}

const TaskHeader: React.FC<Props> = ({ title, quote, image }) => {

    const spacerRef = useRef<HTMLDivElement>(null);

    const sizeSpacer = useCallback(() => {

        if (!spacerRef.current) return;

        const spacer = spacerRef.current;

        spacer.style.height = '0';
        var container = spacer.parentNode as HTMLDivElement;
        var img = spacer.nextElementSibling || spacer.nextSibling as HTMLDivElement;

        if (container && img) {
            var lastContentNode = container.children[container.children.length - 1];
            var h = Math.max(0, container.clientHeight - img.clientHeight);
            spacer.style.height = h + "px";
            while (h > 0 && img.getBoundingClientRect().bottom - img.clientHeight*.65 > lastContentNode.getBoundingClientRect().bottom) {
                spacer.style.height = --h + "px";
            }

            if (lastContentNode.getBoundingClientRect().bottom > img.getBoundingClientRect().bottom) {
                spacer.style.height = ++h + "px";
            }
        }
    }, []);

    useEffect(() => {
        window.addEventListener('resize', sizeSpacer);
        sizeSpacer();

        return () => {
            window.removeEventListener('resize', sizeSpacer);
        }

    }, [sizeSpacer]);

    return <header className="task-header">
        <div className="left">
            <div className="img" style={{
                backgroundImage: `url(${image})`
            }}>
            </div>
        </div>
        <div className="right">
            <h1>{title}</h1>
            <div className="quote-container">
                <div className="q-begin"></div>
                <div ref={spacerRef} className="spacer"></div>
                <div className="q-end"></div>
                <p className="quote">
                    {quote}
                </p>
            </div>
        </div>
    </header>
}

export default TaskHeader;
