import * as PIXI from 'pixi.js';
import { JigsawPiece } from './piece';
import { GameState } from '.';

type Params = {
    onPieceMoved?: (index: number, x: number, y: number) => void,
    gameState: GameState,
    container: HTMLElement,
    onComplete: () => void
}

type Nest = {
    x: number;
    y: number;
    position: { x: number; y: number; };
    id: number,
    pieceId: number | null
}

export default class JigsawGame extends PIXI.Application {
    public baseWidth: number;
    public baseHeight: number;
    private baseRatio: number;
    private scaleRatio = 1;


    private imageScale = 1;
    private preview?: PIXI.Sprite;
    private nests: Nest[] = [];
    pieceW: number = 0;
    pieceH: number = 0;
    pieces: JigsawPiece[] = [];

    lastZIndex = 0;

    private image?: PIXI.Texture;

    constructor(width: number, height: number, private config: Params) {
        super({
            width,
            height,
            transparent: false,
            antialias: true,
            backgroundColor: 0xdfd4c0
        });

        this.baseHeight = height;
        this.baseWidth = width;
        this.baseRatio = height / width;


        window.addEventListener('resize', this.gameResize);

        this.loadAssets([
            ['image', this.config.gameState.image]
        ]).then((resources) => {
            this.image = PIXI.Texture.from('image');

            this.initWidget();
        })
    }

    private loadAssets(assets: [string, string][]) {

        return new Promise(resolve => {
            assets.forEach(assetInfo => {
                this.loader.add(...assetInfo);
            })

            this.loader.load((loader, resource) => {
                resolve(null);
            })

            this.loader.onError.add((...args: any[]) => {
                console.error(...args);
            })

        })

    }

    public destroy() {
        super.destroy(true);
        window.removeEventListener('resize', this.gameResize);
    }

    public gameResize = () => {
        const nW = this.config.container.offsetWidth;
        const nH = nW * this.baseRatio;

        this.renderer.resize(nW, nH);

        this.scaleRatio = (nW / this.baseWidth);

        this.stage.scale.set(this.scaleRatio)
    }

    private initWidget() {
        if (this.image) {
            this.imageScale = Math.min(this.screen.height / this.image.height * .8, this.screen.width / this.image.width * .8, 1);
            this.pieceW = (this.image.width / this.config.gameState.width) * this.imageScale;
            this.pieceH = (this.image.height / this.config.gameState.height) * this.imageScale;
            this.preview = this.initPreview();
            this.createPieces();
            this.nests = this.createNests()

            this.stage.sortableChildren = true;
        }
    }

    private initPreview() {
        const preview = new PIXI.Sprite(this.image);

        preview.anchor.set(.5);
        preview.scale.set(this.imageScale);
        preview.position.y = this.screen.height / 2;
        preview.position.x = this.screen.width / 2;
        preview.alpha = .3;

        this.stage.addChild(preview);

        return preview;
    }

    private createPieces() {
        this.pieces = (new Array(this.config.gameState.width * this.config.gameState.height))
            .fill('')
            .map((_, index) => {
                if (this.image) {
                    const pw = this.image.width / this.config.gameState.width;
                    const ph = this.image.height / this.config.gameState.height;

                    const x = index % this.config.gameState.width;
                    const y = Math.floor(index / this.config.gameState.height);

                    return new PIXI.Texture(this.image as any, new PIXI.Rectangle(x * pw, y * ph, pw, ph));
                } else {
                    return PIXI.Texture.EMPTY;
                }
            })
            .map((texture, index) => {
                const sprite = new JigsawPiece(texture, index);

                sprite.zIndex = this.lastZIndex++;

                sprite.anchor.set(.5);
                sprite.scale.set(this.imageScale);

                sprite
                    .on('pointerup', () => this.snapToNest(sprite, this.nests, this.pieceW, this.pieceH))
                    .on('pointerupoutside', () => this.snapToNest(sprite, this.nests, this.pieceW, this.pieceH))
                    .on('pointerdown', () => sprite.zIndex = this.lastZIndex++)

                return sprite;
            })
            .map(sprite => {
                const gsp = this.config.gameState.pieces.find(({ id }) => id === sprite.id)
                if (gsp) {
                    sprite.position.set(gsp.x, gsp.y)
                }

                return sprite
            });
        this.pieces
            .forEach(sprite => {
                this.stage.addChild(sprite);
            });
    }

    private snapToNest(piece: JigsawPiece, nests: Nest[], pieceW: number, pieceH: number) {
        const target = nests.reduce<Nest | null>((prev, next) => {
            if (next.pieceId === null) {
                if (Math.abs(piece.position.x - next.position.x) < pieceW / 2 && Math.abs(piece.position.y - next.position.y) < pieceH / 2) {
                    return next;
                }
            }

            return prev;
        }, null)

        for (let i = 0; i < nests.length; i++) {
            if (nests[i].pieceId === piece.id) {
                nests[i].pieceId = null;
            }
        }

        if (target) {
            piece.position.x = target.position.x;
            piece.position.y = target.position.y;
            target.pieceId = piece.id;
        }

        if (this.validate()) {
            this.config.onComplete()
        }
    }

    private validate() {
        return this.nests.every(n => n.pieceId === n.id);
    }

    private createNests(): Nest[] {
        return (new Array(this.config.gameState.width * this.config.gameState.height))
            .fill('')
            .map((_, index) => {
                const x = index % this.config.gameState.width;
                const y = Math.floor(index / this.config.gameState.height);

                const position = {
                    x: this.screen.width / 2 + ((x - this.config.gameState.width / 2) * this.pieceW) + this.pieceW / 2,
                    y: this.screen.height / 2 + ((y - this.config.gameState.height / 2) * this.pieceH) + this.pieceH / 2
                }

                return {
                    id: y * this.config.gameState.width + x,
                    pieceId: null,
                    x,
                    y,
                    position
                }
            });
    }

    public movePiece(id: number, x: number, y: number) {
        const piece = this.pieces.find(p => p.id === id);

        if (piece) {
            if (piece.position.x !== x || piece.position.y !== y) {
                piece.position.set(x, y);
            }
        }
    }
}
