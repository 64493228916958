
import React, { useCallback, useState } from 'react';
import Box from '../../../../layout/box';
import Jigsaw from '../../../jagsaw';
import TaskHeader from '../task-header';
import './style.scss';
import portret from '../../../../assets/task-10/portret.png';
import TaskButtons from '../task-buttons';
import { getEndGameRoute } from '../../../../routes/routes';

const ID = '10';
const TITLE = 'Konstytucja';
const QUOTE = 'Dla dobra powszechnego, dla ugruntowania wolności, dla ocalenia ojczyzny naszej i jej granic, z największą stałością ducha niniejszą konstytucję uchwalamy i tę całkowicie za świętą, za niewzruszoną deklarujemy.'

type Props = {
    onComplete?: () => void
}

const Task06Widget: React.FC<Props> = ({ onComplete: completeCallback }) => {

    const [completed, setCompleted] = useState(false);
    const onComplete = useCallback(() => {
        setCompleted(true);
    }, [setCompleted]);

    return <div className="task-06">
        <Box>
            <TaskHeader image={portret} title={TITLE} quote={QUOTE} />
            <div className="persona-info">
            </div>
        </Box>
        <Box>
            <div className="task-widget">
                <div className="game-intro">
                    <h2>Zadanie</h2>
                    <p>
                        Chociaż najsłynniejszym obrazem przedstawiającym wydarzenia z 1791 roku jest ten autorstwa Jana Matejki, to wielu polskich malarzy pragnęło uwiecznić uchwalenie Konstytucji. Jednym z mniej znanych obrazów jest “Uchwalenie Konstytucji 3 Maja”, namalowane przez Kazimierza Wojniakowskiego. Jest to znacznie starszy obraz i być może bardziej oddający rzeczywiste wydarzenia, ponieważ Wojniakowski żył w momencie uchwalenia Konstytucji, natomiast Jan Matejko urodził się dopiero w 1838 roku.<br />
                        <strong>Ułóż właściwie puzzle, aby odtworzyć obraz Kazimierza Wojniakowskiego.</strong></p>

                </div>
                <Jigsaw onComplete={onComplete} />
            </div>
            <TaskButtons id={ID} completed={completed} nextRoute={getEndGameRoute()} />
        </Box>
    </div>
}

export default Task06Widget;
