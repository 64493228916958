import { useUserModuleState } from "..";

export default function useUser() {
    const {user} = useUserModuleState();

    return {
        isLogged: true,
        user
    }
}
