
import LoginForm from "../../components/login-form";
import Box from "../../layout/box";
import './style.scss'

export default function LoginPage() {
    return <div className="page login-page">
        <Box className="login-box">
            <div className="container">
                <h1>Imię gracza</h1>
                <LoginForm />

            </div>
        </Box>
    </div >
}
