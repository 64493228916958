import React, { useCallback } from "react"
import { useHistory } from "react-router"
import useCompleteTask from "../../../modules/tasks/hooks/use-complete-task"
import { getMapRoute } from "../../../routes/routes"
import PrettyButton from "../../pretty-button"
import PrettyLink from "../../pretty-link"

type Props = {
    id: string,
    completed: boolean,
    nextRoute?: string
}

const TaskButtons: React.FC<Props> = ({ completed, id, nextRoute }) => {
    const completeTask = useCompleteTask();
    const history = useHistory();

    const onClickComplete = useCallback(() => {
        completeTask(id);
        history.push(nextRoute || getMapRoute())
    }, [completeTask, id, history, nextRoute]);

    return <div className="task-buttons">
        <PrettyLink route={getMapRoute()}>
            Powrót
        </PrettyLink>
        {completed && <>
            <div className="spacer" style={{flex: '1 1 auto', margin: '0'}}></div>
            <PrettyButton onClick={onClickComplete}>Zadanie wykonane</PrettyButton>
        </>}
    </div>
}

export default TaskButtons;
