
import React, { useCallback } from 'react';
import './style.scss';


import portret from '../../../../assets/task-03/portret.png';
import Box from '../../../../layout/box';
import TaskButtons from '../task-buttons';
import TaskHeader from '../task-header';
import { useState } from 'react';
import Task03Game from './game';

const ID = '03';
const TITLE = 'Ignacy Potocki';
const QUOTE = 'Sejm zawsze gotowym będzie: prawodawczy i ordynaryjny. Rozpoczynać się ma co dwa lata, trwać zaś będzie podług opisu prawa o sejmach. Gotowy, w potrzebach nagłych zwołany, stanowić ma o tej tylko materji, do której zwołanym będzie, lub o potrzebie, po czasie zwołania przypadłej. Prawo żadne na tym ordynaryjnym sejmie, na którym ustanowione było, znoszonym być nie może. (VI)'

type Props = {
    onComplete?: () => void
}

const Task03Widget: React.FC<Props> = ({ onComplete: completeCallback }) => {

    const [completed, setCompleted] = useState(false);
    const onComplete = useCallback(() => {
        setCompleted(true);
    }, [setCompleted]);

    return <div className="task-03">
        <Box>
            <TaskHeader image={portret} title={TITLE} quote={QUOTE} />
            <div className="persona-info">
                <p>
                    <strong>Ignacy Potocki</strong> był wieloletnim działaczem patriotycznym, publikował wiele dzieł dotyczących m.in. konstytucji, reform i edukacji. Był członkiem Komisji Edukacji Narodowej i jednym z dowódców w trakcie Insurekcji Kościuszkowskiej.
            </p>
            </div>
        </Box>
        <Box>
            <div className="task-widget">
                <div className="game-intro">
                    <h2>Zadanie</h2>
                    <p>Od czasu stworzenia konstytucji 3 Maja język polski bardzo się zmienił. Wiele słów zanikło, a wiele innych nabrało nowego znaczenia.<br />
                        <strong>Połącz w pary staropolskie słowo z jego definicją. Czy znasz jakieś współczesne synonimy tych słów?</strong>
                    </p>
                </div>
                <Task03Game onComplete={onComplete} />
                <TaskButtons id={ID} completed={completed} />
            </div>
        </Box>
    </div>
}

export default Task03Widget;
