import { useCallback, useEffect, useReducer, useState } from "react"

type Props = {
    onComplete: () => void
}

type Item = {
    id: string,
    text: string,
    value: number,
    used?: boolean,
}

type State = {
    g1: Item[],
    g2: Item[],
    selected: string | null
}

const Task03Game: React.FC<Props> = ({ onComplete }) => {

    const [state, select] = useReducer((state: State, action: string) => {

        if (state.selected === null) {
            return {
                ...state,
                selected: action
            }
        }

        const item1 = [...state.g1, ...state.g2].find(i => i.id === state.selected);
        const item2 = [...state.g1, ...state.g2].find(i => i.id === action);

        if (item1 && item2) {
            if (item1.value === item2.value) {
                return {
                    selected: null,
                    g1: state.g1.map(item => {
                        if (item.id === item1.id || item.id === item2.id) {
                            return {
                                ...item,
                                used: true
                            }
                        }

                        return item;
                    }),
                    g2: state.g2.map(item => {
                        if (item.id === item1.id || item.id === item2.id) {
                            return {
                                ...item,
                                used: true
                            }
                        }

                        return item;
                    })
                }
            } else {
                return {
                    ...state,
                    selected: null
                }
            }
        }

        return state;
    }, {
        g1: [
            { id: 'i11', text: 'Alamoda', value: 1 },
            { id: 'i12', text: 'Hurm', value: 2 },
            { id: 'i13', text: 'Śniadać', value: 3 },
            { id: 'i14', text: 'Naczesny', value: 4 },
            { id: 'i15', text: 'Rańtuch', value: 5 },
            { id: 'i16', text: 'Jelekole', value: 6 },
        ],
        g2: [
            { id: 'i21', text: 'Modny strój', value: 1 },
            { id: 'i22', text: 'Gromada, tłum', value: 2 },
            { id: 'i23', text: 'Jeść śniadanie', value: 3 },
            { id: 'i24', text: 'Odpowiedni', value: 4 },
            { id: 'i25', text: 'Chusta', value: 5 },
            { id: 'i26', text: 'Za każdym razem', value: 6 },
        ].sort(() => (Math.random() - .5)),
        selected: null
    })

    const [wrong, setWrong] = useState<string[]>([]);

    useEffect(()=> {
        if (wrong.length > 0) {
            const id = setTimeout(() => {
                setWrong([]);
            }, 1000);

            return () => {
                clearTimeout(id);
            }
        }



    }, [wrong, setWrong]);

    const onSelect = useCallback((itemId: string) => {
        if (state.selected) {
            const item1 = [...state.g1, ...state.g2].find(i => i.id === state.selected);
            const item2 = [...state.g1, ...state.g2].find(i => i.id === itemId);

            if (item1 && item2) {
                if (item1.value !== item2.value) {
                    setWrong([item1.id, item2.id]);
                }
            }
        }

        select(itemId)
    }, [state, select, setWrong])

    useEffect(() => {
        const g1 = state.g1.every(i => i.used);
        const g2 = state.g2.every(i => i.used);

        if (g1 && g2) {
            onComplete();
        }
    }, [state, onComplete])

    return <div className="task-03-game">
        <div className="column group-1">
            {
                state.g1.map((item) => {
                    const status = wrong.includes(item.id) ? 'wrong' : (item.used ? 'correct' : (item.id === state.selected ? 'selected' : ''));

                    return <div className={`item-container ${status}`} key={item.id}>
                        <div className="inner">
                            <button onClick={() => onSelect(item.id)}>{item.text}</button>
                        </div>
                    </div>
                })
            }
        </div>
        <div className="column group-2">
            {
                state.g2.map((item) => {
                    const status = wrong.includes(item.id) ? 'wrong' : (item.used ? 'correct' : (item.id === state.selected ? 'selected' : ''));

                    return <div className={`item-container ${status}`} key={item.id}>
                        <div className="inner">
                            <button onClick={() => onSelect(item.id)}>{item.text}</button>
                        </div>
                    </div>
                })
            }
        </div>
    </div>
}

export default Task03Game;
