
import PrettyLink from '../../components/pretty-link';
import Box from '../../layout/box';
import { getRulesRoute } from '../routes';
import './style.scss';


export default function HomePage() {

    return (
        <div className='page home-page'>
            <Box>
                <h1>Witajcie</h1>
                <div className="content">
                    <p>Z okazji 230 rocznicy uchwalenia konstytucji 3{'\u00A0'}Maja przygotowaliśmy grę “Bohaterowie Konstytucji”. W trakcie rozgrywki poznacie okoliczności jej uchwalenia, ważne zmiany, która wprowadziła i najważniejsze osoby, które pracowały nad opracowaniem jej tekstu.
                        Życzymy Wam świetnej zabawy i udanego świętowania!<br />
                        <strong>Wiwat 3{'\u00A0'}Maja! Wiwat Konstytucja!</strong>
                    </p>
                </div>
                <nav className="buttons">
                    <PrettyLink route={getRulesRoute()}>
                        Dalej
                </PrettyLink>
                    {/* <Link className="button start-button" to={getRulesRoute()} >Rozpocznij misję!</Link> */}
                </nav>
            </Box>
        </div>

    )
}
