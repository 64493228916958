import React, { useMemo, useState, useCallback, useReducer, useEffect } from "react";
import MemoryGrid from "./memory-grid";


enum GAME_PHASE {
    WAITING = 'WAITING',
    COMPARING = 'COMPARING',
    ENDED = 'ENDED'
}

export type Item = [string, string]

export interface Props {
    /**
     * Callback wykonywany po wykonaniu zadania
     */
    onComplete?: () => void
    /**
     * Opóźnienie wykonania callbacka `onComplete`
     */
    completeTimeout?: number
    /**
     * Tablica z parami pasujących do siebie grafik
     */
    items: Item[],
    /**
     * URL do grafiki rewersu kafelka
     */
    backImage: string,

    /**
     * Preferowana ilośc kolumn w siatce.
     * Domyślnie warość zależna od ilości elementów w polu `items`.
     * Maksymalna wartość to ilość elementów w polu `items`
     */
    gridWidth?: number
}

/**
 * Widget gry w memory
 *
 * @example
 * <MemoryGame
 *      items={[
 *          [
 *              'image-1a',
 *              'image-1b'
 *          ],
 *          [
 *              'image-2a',
 *              'image-2b'
 *          ]
 *      ]}
 *      backImage='back.png'
 * />
 */
export const MemoryGame: React.FC<Props> = ({ onComplete, completeTimeout = 0, items, backImage, gridWidth }) => {

    const _gridWidth = useMemo(() => Math.min(
        gridWidth || Math.ceil(Math.sqrt(items.length * 2)),
        items.length * 2
    ), [gridWidth, items]);

    const [tiles, setTiles] = useState(() => {
        return items.reduce<TileModel[]>((prev, next, index) => {
            return [
                ...prev,
                { value: index, image: next[0], isHidden: true, isRemoved: false, isMismatched: false },
                { value: index, image: next[1], isHidden: true, isRemoved: false, isMismatched: false }
            ]
            .sort(() => Math.random() - .5)
        }, [])
    })

    const [phase, setPhase] = useState<GAME_PHASE>(GAME_PHASE.WAITING);

    const [activeTiles, setActiveTile] = useReducer((state: number[], value: number | null) => {

        if (value === null) {
            return [];
        }

        if (value === state[0]) {
            return state;
        }

        if (state.length < 2) {
            return [
                ...state,
                value
            ]
        }

        return state;
    }, []);

    const onTileSelected = useCallback((tile: number) => {

        if (phase !== GAME_PHASE.WAITING) return;

        // Nie reagujemy na kliknięcia w odsłonięte płyki.
        if (!tiles[tile].isHidden) return;

        // Nie reagujemy na kliknięcia w usuniete płytki.
        if (tiles[tile].isRemoved) return;

        setActiveTile(tile);

    }, [phase, setActiveTile, tiles])

    useEffect(() => {
        const newTiles = [
            ...tiles
        ]

        let dirty = false;

        activeTiles.forEach(t => {

            if (newTiles[t].isHidden) {
                newTiles[t].isHidden = false;
                dirty = true;
            }
        })

        if (dirty) setTiles(newTiles);
    }, [activeTiles, tiles, setTiles])

    useEffect(() => {
        if (activeTiles.length !== 2) return;

        // Jeżeli odkryliśmy 2 kafelki porównujemy ich wartości
        setPhase(GAME_PHASE.COMPARING);
        let id: number;

        const newTiles = [
            ...tiles
        ]

        // Jeżeli wartości się zgadzają
        if (tiles[activeTiles[0]].value === tiles[activeTiles[1]].value) {
            tiles[activeTiles[0]].isHidden = false;
            tiles[activeTiles[0]].isRemoved = true;
            tiles[activeTiles[1]].isHidden = false;
            tiles[activeTiles[1]].isRemoved = true;


            setActiveTile(null)
            setTiles(newTiles);
            setPhase(GAME_PHASE.WAITING);

        } else {
            id = window.setTimeout(() => {
                tiles[activeTiles[0]].isMismatched = true;
                tiles[activeTiles[1]].isMismatched = true;
                setActiveTile(null)
                setTiles(newTiles);

                setTimeout(() => {
                    tiles[activeTiles[0]].isHidden = true;
                    tiles[activeTiles[1]].isHidden = true;
                    tiles[activeTiles[0]].isMismatched = false;
                    tiles[activeTiles[1]].isMismatched = false;

                    setPhase(GAME_PHASE.WAITING);
                }, 1000)
            }, 500);
        }

        return () => clearTimeout(id);

    }, [activeTiles, setPhase, tiles, setTiles])

    useEffect(() => {
        if (tiles.every(t => t.isHidden === false)) {
            setPhase(GAME_PHASE.ENDED);
        }
    }, [tiles])

    useEffect(() => {
        if (phase === GAME_PHASE.ENDED) {
            const id = setTimeout(() => {
                onComplete && onComplete()
            }, completeTimeout);

            return () => {
                clearTimeout(id);
            }
        }
    }, [phase, onComplete, completeTimeout]);

    return (
        <>
            <div style={{ width: '95%', margin: '0 auto', maxWidth: '120vh' }}>
                <MemoryGrid
                    gridWidth={_gridWidth}
                    models={tiles}
                    back={backImage}
                    onTileSelected={onTileSelected}
                ></MemoryGrid>
            </div>
        </>
    )
}

export type TileModel = {
    value: number,
    image: string,
    isHidden: boolean,
    isRemoved: boolean,
    isMismatched: boolean
}


export default MemoryGame;
