import { useCallback, useRef } from "react";
import { useForm } from "react-hook-form"
import PrettyButton from "../../../pretty-button";
import papierImg from '../../../../assets/task-04/papier.png';
import jsPDF from "jspdf";
import { font } from './Charmonman';

type Props = {
    onComplete: () => void
}

const Task04Game: React.FC<Props> = ({ onComplete }) => {

    const { register, getValues } = useForm();
    const canvasRef = useRef<HTMLCanvasElement>(null);

    const generate = useCallback(() => {
        const text = getValues().text;

        // if (canvasRef.current) {

        const image = document.createElement('img');
        image.addEventListener('load', () => {

            // @ts-ignore
            var pdf = new jsPDF({
                orientation: 'p',
                format: 'a4',
                unit: 'pt'
            });

            const MM_TO_PT = 2.8346456693;


            const pageMargin = 20 * MM_TO_PT ;
            const imageWidth = 809 * MM_TO_PT
            const imageHeight = 1370 * MM_TO_PT;

            const ratio = imageHeight / imageWidth;
            const pWidth = 210 * MM_TO_PT;

            pdf.addImage({
                imageData: image,
                x: pageMargin,
                y: 5 * MM_TO_PT,
                width: pWidth - (2 * pageMargin),
                height: (pWidth - (2 * pageMargin)) * ratio
            });

            pdf.addFileToVFS("Charmonman.ttf", font);
            pdf.addFont("Charmonman.ttf", "Charmonman", "normal");
            pdf.setFont('Charmonman', 'normal', 400);



            const rectW = 130 * MM_TO_PT
            const rectH = 170 * MM_TO_PT;
            const rectX = pWidth/2
            const rectY = 60 * MM_TO_PT
            const rectCenterY = rectH / 2

            let height = Infinity;
            let fontSize = 30;

            while (height > rectH && fontSize >= 18) {
                pdf.setFontSize(--fontSize);

                const lines = pdf.splitTextToSize(text, rectW)
                const lineHeight = pdf.getLineHeight()

                height = lines.length * lineHeight;
            }

            const lines = pdf.splitTextToSize(text, rectW)

            const textCenterY = height / 2;

            pdf.text(lines, rectX, rectY + (rectCenterY - textCenterY), {
                maxWidth: pWidth,
                align: 'center'
            })


            pdf.save("dyplom.pdf");
        });

        image.src = papierImg;
    }, [getValues]);

    return <div className="task-04-game">
        <textarea placeholder="....." {...register('text')}></textarea>
        <div className="button">
            <PrettyButton onClick={generate}>
                Generuj
        </PrettyButton>

        </div>

        <canvas width="809" height="1370" ref={canvasRef} style={{
            fontFamily: 'Charmonman',
            display: 'none'
        }}>
        </canvas>
    </div>
}

export default Task04Game;
