
import React, { useCallback, useMemo, useState } from 'react';
import Box from '../../../../layout/box';
import TaskHeader from '../task-header';
import './style.scss';
import portret from '../../../../assets/task-05/portret.png';
import TaskButtons from '../task-buttons';
import MemoryGame, { Item } from '../../../memo';

import backImg from '../../../../assets/task-05/back.png';

import img11 from '../../../../assets/task-05/i11.png';
import img12 from '../../../../assets/task-05/i12.png';
import img21 from '../../../../assets/task-05/i21.png';
import img22 from '../../../../assets/task-05/i22.png';
import img31 from '../../../../assets/task-05/i31.png';
import img32 from '../../../../assets/task-05/i32.png';
import img41 from '../../../../assets/task-05/i41.png';
import img42 from '../../../../assets/task-05/i42.png';
import img51 from '../../../../assets/task-05/i51.png';
import img52 from '../../../../assets/task-05/i52.png';
import img61 from '../../../../assets/task-05/i61.png';
import img62 from '../../../../assets/task-05/i62.png';


const ID = '05';
const TITLE = 'Jan Suchorzewski';
const QUOTE = 'Jako nominowanie wszystkich ministrów, tak i wezwanie z nich jednego od każdego administracji wydziału do rady swojej czyli straży króla jest prawem. Wezwanie do ministra do zasiadania w straży na lat dwa będzie, z wolnem onego nadal przez króla potwierdzeniem.'

type Props = {
    onComplete?: () => void
}

const Task04Widget: React.FC<Props> = ({ onComplete: completeCallback }) => {

    const [completed, setCompleted] = useState(false);
    const onComplete = useCallback(() => {
        setCompleted(true);
    }, [setCompleted]);

    const memoryItems: Item[] = useMemo(() => {
        return [
            [img11, img62],
            [img21, img22],
            [img31, img32],
            [img12, img42],
            [img51, img52],
            [img61, img41],
        ]
    }, [])

    return <div className="task-05">
        <Box>
            <TaskHeader image={portret} title={TITLE} quote={QUOTE} />
            <div className="persona-info">
                <p>
                    <strong>Jan Suchorzewski</strong> był posłem ziemi kaliskiej. Z jego inicjatywy stworzono dokument Miasta nasze królewskie wolne w państwach Rzeczypospolitej, który włączono do konstytucji. Mimo to był aktywnym przeciwnikiem Konstytucji 3 Maja i członkiem konfederacji targowickiej.
                </p>
            </div>
        </Box>
        <Box>
            <div className="task-widget">
                <div className="game-intro">
                    <h2>Zadanie</h2>
                    <p className="type-2">Na obrazie widać także wiele innych ważnych postaci zaangażowanych w proces uchwalania konstytucji 3 Maja. Do najważniejszych z nich należą:</p>
                    <ul>
                        <li><strong>Ignacy Wyssogota Zakrzewski</strong> - Dwukrotnie wybrany na prezydenta Warszawy, wielki zwolennik Konstytucji 3 Maja, jeden z inicjatorów Insurekcji Kościuszkowskiej.</li>
                        <li><strong>Jan Dekert</strong> - Prezydent Warszawy. Choć w momencie uchwalania konstytucji już nie żył, Jan Matejko upamiętnił go na obrazie, by docenić jego działania na rzecz prawa mieszczan.</li>
                        <li><strong>Scipione Piattoli</strong> - sekretarz Stanisława Augusta Poniatowskiego, współautor Konstytucji 3 Maja, propagował sojusz polsko-pruski. Po upadku Rzeczypospolitej zajmował się szkolnictwem i tłumaczeniami.</li>
                        <li><strong>Józef Poniatowski</strong> - bratanek króla, dowódca armii Rzeczypospolitej. Walczył w insurekcji kościuszkowskiej, w wojnach Napoleońskich. Zasłynął w nierozstrzygniętej bitwie pod Raszynem 19 kwietnia 1809 r. gdzie zaimponował bohaterstwem jako dowódca i strateg.. Zginął w 1813 w bitwie pod Lipskiem.</li>
                        <li><strong>Jan Kiliński</strong> - mistrz szewski, był członkiem Rady Warszawy. W trakcie insurekcji kościuszkowskiej stanął na czele mieszczan i dzięki jego działaniu stolica przyłączyła się do działań wojennych.</li>
                        <li><strong>Anna Charlotta Dorota von Medem</strong> - jedna z nielicznych postaci kobiecych na obrazie. Od 1790 roku hrabianka gościła na dworze Stanisława Augusta.</li>
                    </ul>
                    <p><strong>Połącz w pary ich wizerunki i imiona.</strong></p>

                </div>
                <MemoryGame items={memoryItems} backImage={backImg} onComplete={onComplete} />
            </div>
            <TaskButtons id={ID} completed={completed} />
        </Box>
    </div>
}

export default Task04Widget;
