import { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import GameMap from "../../components/map";
import useBodyClass from "../../layout/hooks/use-body-class";
import mapImage from '../../assets/images/map/map.png';
import './style.scss';

type ContainerProsp = {
    scroll: 'horizontal' | 'vertical',
    windowWidth: number,
    windowHeight: number
}

const Page = styled.div`
    height: 100%;
    max-height: 100%;

    .layout-box {
        margin: 0 auto;
    }

    .footer {
        padding: 4rem 0 2rem;
    }
`;

const Container = styled.div<ContainerProsp>`
    width: 100%;

    ${({ scroll }) => {
        if (scroll === 'horizontal') {
            return `
                    overflow-x: scroll;
                    overflow-y: hidden;
                    height: 100%;
                `;
        }
    }}

    position: relative;

    .map-inner {
        position: relative;

        transition: all .5s;

        ${({ scroll, windowHeight }) => {
        if (scroll === 'horizontal') {
            return `
                    padding-top: 0;
                    width: ${windowHeight * 1.78}px;
                    height: ${windowHeight}px;
                `;
        }
        if (scroll === 'vertical') {
            return `
                width: 100%;
                padding-top: 56%;
            `;
        }
    }}
    }

    .map {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;

        background: url(${mapImage}) 50% 50% no-repeat;
        background-size: cover;
    }
`;

export default function MapPage() {

    useBodyClass('no-image');

    const pageRatio = 762 / 1357;

    const [scroll, setScroll] = useState<ContainerProsp['scroll']>('vertical');
    const [windowWidth, setWindowWidth] = useState(0);
    const [windowHeight, setWindowHeight] = useState(0);

    const onResize = useCallback(() => {
        const windowRatio = window.innerHeight / window.innerWidth;

        setScroll(windowRatio < pageRatio ? 'vertical' : 'horizontal');

        setWindowWidth(window.innerWidth);
        setWindowHeight(window.innerHeight);
    }, [setScroll, setWindowWidth, setWindowHeight, pageRatio])

    useEffect(() => {
        window.addEventListener('resize', onResize);
        window.addEventListener('scroll', onResize);
        onResize();

        return () => {
            window.removeEventListener('resize', onResize);
            window.removeEventListener('scroll', onResize);
        }
    }, [onResize])

    return <Page>
        <Container
            scroll={scroll}
            windowWidth={windowWidth}
            windowHeight={windowHeight}
            className={scroll}
        >
            <div className="map-inner">
                <div className="map">
                    <GameMap />
                </div>
            </div>
        </Container>

    </Page>
}
