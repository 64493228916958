import { useEffect } from "react";
import { useForm } from "react-hook-form";

type Props = {
    onComplete: (value: boolean) => void
}
const answers: {[key: string]: string} = {
    input01: 'jutrzenko',
    input02: 'piosenką',
    input03: 'U Polaków',
    input04: 'w ręku króla',
    input05: 'trzeci maj',
    input06: 'Kołłątaj',
    input07: 'wolność',
    input08: 'tryumfujesz',
}

const Task09Game: React.FC<Props> = ({ onComplete }) => {


    const {register, watch} = useForm();
    const values = watch();

    useEffect(() => {
        const result = Object.keys(answers)
            .every(key => {
                return answers[key] && values[key] && answers[key].trim().toLocaleLowerCase() === values[key].trim().toLocaleLowerCase();
            });
            if (result) {
                onComplete(result);
            }

    }, [values, onComplete])

    return <div className="task-09-game">
        <div className="song">
            <h2>Mazurek 3 maja</h2>
            Witaj majowa <input type="text" {...register('input01')} />,<br />
            Świeć naszej polskiej krainie,<br />
            Uczcimy ciebie <input type="text" {...register('input02')} />,<br />
            Która w całej Polsce słynie.<br />
            <br />
            Witaj maj, piękny maj,<br />
            <input type="text" {...register('input03')} /> błogi raj.<br />
            <br />
            Nierząd braci naszych cisnął,<br />
            Niemoc <input type="text" {...register('input04')} /> spała,<br />
            A wtem <input type="text" {...register('input05')} /> zabłysnął -<br />
            I nasza Polska powstała.<br />
            <br />
            Witaj maj, trzeci maj,<br />
            Wiwat wielki <input type="text" {...register('input06')} />!<br />
            <br />
            Witaj dniu trzeciego maja,<br />
            który <input type="text" {...register('input07')} /> nam zwiastujesz.<br />
            Pierzchła już ciemiężców zgraja.<br />
            Polsko, dzisiaj <input type="text" {...register('input08')} />!<br />
            <br />
            Witaj maj, piękny maj,<br />
            U Polaków błogi raj.<br />

        </div>
    </div>
}

export default Task09Game;
