import React, { useEffect, useState } from 'react';
import { BrowserRouter, Redirect, Route, Switch, useHistory, useLocation } from 'react-router-dom';
// import DebugPage from './routes/debug';
import EndGamePage from './routes/end-game';
import HomePage from './routes/home';
import LoginPage from './routes/login';
import MapPage from './routes/map';
import { getEndGameRoute, getHomeRoute, getLoginRoute, getMapRoute, getRulesRoute, getTaskRoute } from './routes/routes';
import RulesPage from './routes/rules';
import TaskPage from './routes/task';
import ScrollToTop from '@mborecki/react-scroll-to-top';
import useIsLogged from './modules/user/hooks/use-is-logged';
import { USER_MODULE_ACTION, useUserModuleDispatch } from './modules/user';
import Footer from './components/footer';
import PreEvent from './routes/pre-event';

// const LIVE_START = 1620021600000;
const LIVE_START = 1619789389974; //DEV

export default function App() {

    const [isLive, setIsLive] = useState<boolean | null>(null)

    useEffect(() => {
        const id = setInterval(() => {
            setIsLive(Date.now() > LIVE_START);
        }, 10000);
        setIsLive(Date.now() > LIVE_START);

        return () => {
            clearInterval(id);
        }
    }, [setIsLive]);

    return (
        <>

            {(isLive === true) && <BrowserRouter>
                <Routes />
            </BrowserRouter >}
            {
                (isLive === false) && <PreEvent />
            }
        </>
    )
}

const OnlyForLoggedRoute: React.FC<any> = ({ children, ...rest }: any) => {

    const { isLogged } = useIsLogged();

    return <Route {...rest}>{
        isLogged
            ? children
            : <Redirect to={getLoginRoute()} />
    } </Route>
}

const OnlyForUnloggedRoute = ({ children, ...rest }: any) => {

    const { isLogged } = useIsLogged();

    return <Route {...rest}>{
        isLogged
            ? <Redirect to={getMapRoute()} />
            : children
    } </Route>
}


const Routes: React.FC<{}> = () => {

    const { pathname } = useLocation()

    return <>
        <ScrollToTop trigger={pathname} />
        <Switch>
            <Route path={getHomeRoute()} exact>
                <HomePage />
                <Footer />

            </Route>

            <Route path={getRulesRoute()}>
                <RulesPage />
                <Footer />
            </Route>
            <OnlyForLoggedRoute path={getMapRoute()}>
                <MapPage />
            </OnlyForLoggedRoute>
            <OnlyForLoggedRoute path={getTaskRoute()}>
                <TaskPage />
                <Footer />
            </OnlyForLoggedRoute>

            <OnlyForUnloggedRoute path={getLoginRoute()} exact >
                <LoginPage />
                <Footer />
            </OnlyForUnloggedRoute>

            <OnlyForLoggedRoute path={getEndGameRoute()} exact>
                <EndGamePage />
                <Footer />
            </OnlyForLoggedRoute>

            <Route path={'/reset'}>
                <Reset />
            </Route>

            {/* <Route path={'/debug'}><DebugPage /></Route> */}

            <Route component={HomePage} />
        </Switch>
        {/* <Cookies type="web" /> */}
    </>
}


function Reset() {

    const dispatch = useUserModuleDispatch();
    const history = useHistory();

    useEffect(() => {
        dispatch({
            type: USER_MODULE_ACTION.RESET
        });
        history.replace(getHomeRoute());
    }, [dispatch, history])

    return <></>;
}
