import React, { useEffect, useReducer } from "react"
import PrettyButton from "../../../pretty-button"

type Props = {
    onComplete: () => void
}

type Item = {
    id: number
    text: string,
    isCorrect: boolean,
    value: boolean | null
}

const Task01Game: React.FC<Props> = ({ onComplete }) => {
    const [items, action] = useReducer((state: Item[], action: { id: number, value: boolean }) => {
        return state.map(i => {
            if (i.id === action.id) {
                return {
                    ...i,
                    value: action.value
                }
            };

            return i;
        })
    }, [
        { id: 0, isCorrect: true, value: null, text: 'Zniesienie zasady liberum veto' },
        { id: 1, isCorrect: true, value: null, text: 'Objęcie ochroną chłopów' },
        { id: 2, isCorrect: false, value: null, text: 'Wprowadzenie edukacji dla wszystkich dzieci do 10 roku życia' },
        { id: 3, isCorrect: false, value: null, text: 'Wprowadzenie urzędu prezydenta' },
        { id: 4, isCorrect: true, value: null, text: 'Wprowadzenie dziedzicznej monarchii konstytucyjnej' },
        { id: 5, isCorrect: false, value: null, text: 'Ustanowienie białego i czerwonego oficjalnymi barwami Rzeczypospolitej' },
    ] as Item[])

    useEffect(() => {
        if (items.every(i => i.value === i.isCorrect)) {
            onComplete();
        }
    }, [items, onComplete]);

    return <div className="task-01-game">
        {items.map(i => {

            return <div className="item" key={i.id}>
                <div className="content">
                    <div className="inner">
                        <div className="text">
                            {i.text}
                        </div>
                    </div>
                </div>
                <div className="buttons">

                    {
                        [true, false].map((opt, index) => {

                            const selected = opt === i.value;
                            const variant = (i.isCorrect === opt) ? 'correct' : 'incorrect';

                            return <React.Fragment key={index}>
                                <PrettyButton theme="true-false" variant={selected ? variant : ''} onClick={() => { action({ id: i.id, value: opt }) }}>
                                    {opt ? 'Prawda' : 'Fałsz'}
                                </PrettyButton>
                            </React.Fragment>
                        })
                    }
                </div>
            </div>
        })}
    </div>
}

export default Task01Game;
