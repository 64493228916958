import React from "react";
import { useParams } from "react-router";
import TaskComponent from "../../components/task";

export default function TaskPage() {
    const { id } = useParams<{ id: string }>()

    return <div className="page task-page">
            {id && <TaskComponent id={id} />}
    </div>
}
