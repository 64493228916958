import React, { useCallback } from "react";
import PrettyButton from "../../components/pretty-button";
import Box from "../../layout/box";
import './style.scss';

import { font } from '../../components/task/tasks/task-04/Charmonman';
import useUser from "../../modules/user/hooks/use-user";
import jsPDF from "jspdf";

import dyplomImg from '../../assets/dyplom.png';

export default function EndGamePage() {

    const { user } = useUser();

    const downloadDyplom = useCallback(() => {
        const text = user?.login || '';

        // if (canvasRef.current) {

        const image = document.createElement('img');
        image.addEventListener('load', () => {
            // if (canvasRef.current) {
            //     const ctx = canvasRef.current.getContext('2d');

            //     if (ctx) {
            //         ctx?.drawImage(image, 0, 0);
            //         ctx.font = "30px Charmonman"
            //         ctx.textAlign = 'center';
            //         ctx.textBaseline = 'middle';
            //         wrapText(ctx, text, 809 / 2, 1370 / 2, 500, 35);

            //         setTimeout(downloadFile, 200);
            //     }
            // }

            // @ts-ignore
            var pdf = new jsPDF({
                orientation: 'p',
                format: 'a4',
                unit: 'pt'
            });

            const MM_TO_PT = 2.8346456693;


            const pageMargin = 20 * MM_TO_PT;
            const imageWidth = 809 * MM_TO_PT
            const imageHeight = 1370 * MM_TO_PT;

            const ratio = imageHeight / imageWidth;
            const pWidth = 210 * MM_TO_PT;

            pdf.addImage({
                imageData: image,
                x: pageMargin,
                y: 5 * MM_TO_PT,
                width: pWidth - (2 * pageMargin),
                height: (pWidth - (2 * pageMargin)) * ratio
            });

            pdf.addFileToVFS("Charmonman.ttf", font);
            pdf.addFont("Charmonman.ttf", "Charmonman", "normal");
            pdf.setFont('Charmonman', 'normal', 400);



            const rectW = 130 * MM_TO_PT
            const rectH = 170 * MM_TO_PT;
            const rectX = pWidth / 2
            const rectY = 60 * MM_TO_PT
            const rectCenterY = rectH / 2

            let height = Infinity;
            let fontSize = 30;

            while (height > rectH && fontSize >= 18) {
                pdf.setFontSize(--fontSize);

                const lines = pdf.splitTextToSize(text, rectW)
                const lineHeight = pdf.getLineHeight()

                height = lines.length * lineHeight;
            }

            const lines = pdf.splitTextToSize(text, rectW)

            const textCenterY = height / 2;

            pdf.text(lines, rectX, rectY + (rectCenterY - textCenterY), {
                maxWidth: pWidth,
                align: 'center'
            })


            pdf.save("dyplom.pdf");
        });

        image.src = dyplomImg;
    }, [user]);

    return <div className='page end-game-page'>
        <Box>
            <div className="content">
                <h1>Gratulacje!</h1>
                <p>
                    Udało Ci się wykonać wszystkie zadania gry <strong>Bohaterowie Konstytucji</strong>. Poznałeś/poznałaś jej treść i odkryłeś/odkryłaś osoby, które przyczyniły się do jej powstania.
                </p>
                <p className="type-2">
                    Dziedzictwo Konstytucji 3 Maja trwa nadal - co roku w tym dniu wywieszamy flagi i świętujemy polską niepodległość, równość wszystkich obywateli niezależnie od pochodzenia i czujemy się dumni z naszych przodków. Historia skrywa jeszcze dziesiątki podobnych wydarzeń, w których Polacy z wielkim poświęceniem działali na rzecz naszej ojczyzny. Te historie czekają tylko, aż je odkryjesz…
                </p>
                <p>Jeśli chcesz odebrać okolicznościową monetę  „ROK KONSTYTUCJI 3 MAJA”  zgłoś to mailowo na <a target="_blank" rel="noreferrer" href="mailto:bikbox@wp.pl">bikbox@wp.pl</a>. Pierwsze 200 osób zostaną nagrodzone.</p>
                <p className="right">
                    ORGANIZATORZY GRY
                </p>

            </div>
            <div className="buttons">
                <PrettyButton onClick={downloadDyplom}>
                    Pobierz dyplom
                    </PrettyButton>
            </div>
        </Box>
    </div>
}
