import { Link } from "react-router-dom";
import styled from "styled-components";

type Props = {
   route: string
}

const Container = styled.div`
   display: inline-block;

   background: linear-gradient(
      90deg,
      #ffb302 0%,
      #ffba01 4%,
      #ffba01 18%,
      #ffdf01 35%,
      #ffdf01 62%,
      #fec300 83%,
      #fec300 93%,
      #ffb302 100%
   );

   border-radius: .45em;
   padding: .14em;
   position: relative;

   .inner {
      background: linear-gradient(
         0deg,
         #eb4747 0%,
         #b10d0d 16%,
         #b10d0d 84%,
         #f34f4f 100%
      );
      border-radius: .4em;
      box-shadow: inset 0px 0px 1px 1px #BF2114;

      &:hover {
         background: linear-gradient(
            0deg,
            #b10d0b 0%,
            #e2403e 16%,
            #e2403e 84%,
            #be1b17 100%
         );
         border-radius: 13px;
         box-shadow: inset 0px 0px 1px 1px #BF2114;
      }
   }

   a {
      color: white;
      text-decoration: none;
      text-transform: uppercase;
      font-weight: 500;
      font-size: .75rem;
      letter-spacing: .15em;
      padding: 0 1.5em;
      display: block;
      line-height: 1.5em;
      text-align: center;
   }
`;

const PrettyLink: React.FC<Props> = ({ route, children }) => {
   return <Container>
      <div className="inner">
         <Link to={route}>{children}</Link>
      </div>
   </Container>
}

export default PrettyLink;
