export function getHomeRoute() {
    return '/'
}

export function getLoginRoute() {
    return '/logowanie';
}

export function getRulesRoute() {
    return '/zasady';
}

export function getMapRoute() {
    return '/mapa';
}

export function getTaskRoute(id: string = ':id') {
    return `/zadanie/${id}`
}

export function getEndGameRoute() {
    return '/koniec-gry'
}
