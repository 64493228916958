import React, { useMemo } from "react";
import PlaceholderWidget from "./tasks/placeholder";
import Task01Widget from "./tasks/task-01";
import Task02Widget from "./tasks/task-02";
import Task03Widget from "./tasks/task-03";
import Task04Widget from "./tasks/task-04";
import Task05Widget from "./tasks/task-05";
import Task06Widget from "./tasks/task-06";
import Task07Widget from "./tasks/task-07";
import Task08Widget from "./tasks/task-08";
import Task09Widget from "./tasks/task-09";
import Task10Widget from "./tasks/task-10";

type Props = {
    id: string,
    onComplete: () => void
}

const TaskWidget: React.FC<Props> = ({ id, onComplete }) => {

    const TaskWidget = useMemo(() => {

        if (!id) {
            return <></>
        }

        switch (id) {
            case '01':
                return <Task01Widget onComplete={onComplete} />;
            case '02':
                return <Task02Widget onComplete={onComplete} />;
            case '03':
                return <Task03Widget onComplete={onComplete} />;
            case '04':
                return <Task04Widget onComplete={onComplete} />;
            case '05':
                return <Task05Widget onComplete={onComplete} />;


            case '06':
                return <Task06Widget onComplete={onComplete} />;
            case '07':
                return <Task07Widget onComplete={onComplete} />;
            case '08':
                return <Task08Widget onComplete={onComplete} />;
            case '09':
                return <Task09Widget onComplete={onComplete} />;
            case '10':
                return <Task10Widget onComplete={onComplete} />;

            default:
                return <PlaceholderWidget onComplete={onComplete} />;
        }
    }, [id, onComplete]);

    return TaskWidget;
}

export default TaskWidget;
