import raszynImg from '../../assets/raszyn.png';
import michalowiceImg from '../../assets/michalowice.png';
import Box from '../../layout/box';
import './style.scss';

type Props = {}

const Footer: React.FC<Props> = () => {
    return <footer className="page-footer">
        <Box>
            <div className="container">
                <h3>Organizator</h3>
                <div className="img">
                    <img src={raszynImg} alt="" />
                </div>

                <p>Dofinansowane z budżetu Gminy Michałowice w ramach dotacji dla organizacji pozarządowych</p>
                <div className="img">
                    <img src={michalowiceImg} alt="" />
                </div>
            </div>
        </Box>
    </footer>
}

export default Footer;
