
import React, { useCallback, useState } from 'react';
import Box from '../../../../layout/box';
import TaskButtons from '../task-buttons';
import TaskHeader from '../task-header';
import './style.scss';
import portret from '../../../../assets/task-01/portret.png';
import Task01Game from './game';

const ID = '01';
const TITLE = 'Franciszek Ksawery Branicki';
const QUOTE = 'Wszystko i wszędzie większością głosów udecydowane być powinno; przeto liberum veto, konfederacje wszelkiego gatunku i sejmy konfederackie, jako duchowi niniejszej konstytucji przeciwne, rząd obalające, społeczność niszczące, na zawsze znosimy.'

type Props = {
    onComplete?: () => void
}

const Task01Widget: React.FC<Props> = ({ onComplete: completeCallback }) => {

    const [completed, setCompleted] = useState(false);
    const onComplete = useCallback(() => {
        setCompleted(true);
    }, [setCompleted]);

    return <div className="task-01">
        <Box>
            <TaskHeader image={portret} title={TITLE} quote={QUOTE} />
            <div className="persona-info">
                <p>
                    <strong>Franciszek Ksawery Branicki</strong> był jednym z wielu przeciwników Konstytucji 3 Maja. Obawiał się ograniczeń przywilejów szlachty kosztem rosnących wpływów króla. Współtwórca konfederacji targowickiej, która doprowadziła do II rozbioru Polski. Stanisław Wyspiański opisuje jego losy w dramacie “Wesele”
            </p>
            </div>
            <div className="song">
                <strong>CHÓR</strong><br />
                Hulaj dusza, z nami w zgodzie,<br />
                potańcujemy w gospodzie;<br />
                pocałuj się z nami, pocałuj,<br />
                nie żałujta, hetmanie, kieski,<br />
                braliśta pieniążek moskieski,<br />
                hej, hetmanie, hetmanie Branecki!!<br />
                <br />

                <strong>HETMAN</strong><br />
                Bierzcie złoto, pali złoto.<br />
                <br />

                <strong>CHÓR</strong><br />
                Pali pieniążek moskieski?<br />
                <br />

                <strong>HETMAN</strong><br />
                Piekielna mnie dziś gospoda:<br />
                diabły moją piją krew;<br />
                szarpają mi pierś, plecyska,<br />
                psy zjawiska, łby ogniska;<br />
                szarpają, sięgają trzew! (Wesele)<br />

            </div>
        </Box>
        <Box>
            <div className="task-widget">
                <div className="game-intro">
                    <h2>Zadanie</h2>
                    <p>Czy tę zmianę wprowadziła Konstytucja 3 Maja?<br />
                        <strong>Wybierz prawidłową odpowiedź klikając pole PRAWDA lub FAŁSZ.</strong>
                    </p>
                </div>

                <Task01Game onComplete={onComplete} />
                <TaskButtons id={ID} completed={completed} />
            </div>
        </Box>
    </div>
}

export default Task01Widget;
