
import React, { useCallback, useState } from 'react';
import Box from '../../../../layout/box';
import TaskButtons from '../task-buttons';
import TaskHeader from '../task-header';
import './style.scss';
import portret from '../../../../assets/task-07/portret.png';
import Task07Game from './game';

type Props = {
    onComplete?: () => void
}

const ID = '07';
const TITLE = 'Hugo Kołłątaj';
const QUOTE = 'Stanowimy przeto, iż po życiu, jakiego nam dobroć Boska pozwoli, elektor dzisiejszy Saski w Polsce królować będzie. Dynastja przyszłych królów polskich zacznie się na osobie Fryderyka Augusta, dzisiejszego elektora Saskiego, którego sukcesorem de lumbis 3) z płci męskiej tron Polski przeznaczamy. Najstarszy syn króla panującego po ojcu na tron następować ma. (VII)'

const Task07Widget: React.FC<Props> = ({ onComplete: completeCallback }) => {

    const [completed, setCompleted] = useState(false);
    const onComplete = useCallback(() => {
        setCompleted(true);
    }, [setCompleted]);

    return <div className="task-07">
        <Box>
            <TaskHeader image={portret} title={TITLE} quote={QUOTE} />
            <div className="persona-info">
                <p>
                    <strong>Hugo Kołłątaj</strong> - doktor prawa i teologii, współtwórca Konstytucji 3 Maja, współpracownik Komisji Edukacji Narodowej. Propagował reformy i otwieranie nowych szkół i uniwersytetów. Był pionierem badań geologicznych.
                </p>
            </div>
        </Box>
        <Box>
            <div className="task-widget">
                <div className="game-intro">
                    <h2>Zadanie</h2>
                    <p>Demokracja opiera się na zachowaniu równowagi pomiędzy 3 władzami: ustawodawczą, wykonawczą i sądowniczą. Nazywamy to trójpodziałem władzy i w Polsce został wprowadzony wraz z Konstytucją 3 Maja.<br />
                        <strong>Przeciągnij symbole władz do ich opisów w prostokącie. Następnie przeciągnij do nich odpowiednie nazwy.</strong>
                    </p>

                </div>
                <div className={`game-container ${completed ? 'hide' : ''}`}>
                    <Task07Game onComplete={onComplete} />
                </div>
                <TaskButtons id={ID} completed={completed} />
            </div>
        </Box>
    </div>
}

export default Task07Widget;
