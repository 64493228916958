import makeStore from "@mborecki/react-create-store";
import userReducer from "./reducer";
import { User } from "./types";

const USER_MODULE_LS_KEY = 'user';

export type UserModuleState = {
    user?: User
}

const initUserModuleState = {};

export enum USER_MODULE_ACTION {
    CREATE_USER = 'create-user',
    COMPLETE_TASK = 'complete-task',

    RESET = 'reset'
}

export type UserModuleAction = {
    type: USER_MODULE_ACTION,
    payload?: any
}

const [
    UserModuleProvider,
    useUserModuleDispatch,
    useUserModuleState
] = makeStore(userReducer, initUserModuleState, USER_MODULE_LS_KEY)


export {
    initUserModuleState,
    UserModuleProvider,
    useUserModuleDispatch,
    useUserModuleState
}
