
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import './style.scss';
import crossword from './data';
import portret from '../../../../assets/task-06/portret.png';

import useChainInput, { ChainInfo } from './use-chain-input';
import Box from '../../../../layout/box';
import TaskHeader from '../task-header';
import TaskButtons from '../task-buttons';

type Props = {
    onComplete: () => void
}

export type CrosswordField = {
    type: 'label' | 'input',
    position: {
        x: number,
        y: number
    },
    chain?: ChainInfo[],
    extraClass?: string,
    text?: string,
    correct?: string,
    pencil?: string,
    marked?: boolean
}


const ID = '06';
const TITLE = 'Ksiądz Prawosławny';
const QUOTE = 'Że zaś ta sama wiara święta przykazuje nam kochać bliźnich naszych, przeto wszystkim ludziom, jakiegokolwiek bądź wyznania, pokój w wierze i opiekę rządową winniśmy i dlatego wszelkich obrządków i religij wolność w krajach polskich, podług ustaw krajowych, warujemy (I).'

const Task06Widget: React.FC<Props> = () => {

    const containerRef = useRef<HTMLDivElement>(null)
    const [containerWidth, setContainerWidth] = useState(0);
    const crosswordColumnCount = 13;
    const crosswordRowCount = 11;
    const cellSize = useMemo(() => {
        return containerWidth / crosswordColumnCount;
    }, [containerWidth])

    const [completed, setCompleted] = useState(true);
    const onComplete = useCallback(() => {
        setCompleted(true);
    }, [setCompleted]);

    const { registerContainer, registerInput } = useChainInput();

    const onResize = useCallback(() => {
        if (containerRef.current) {
            setContainerWidth(containerRef.current.getBoundingClientRect().width);
        }
    }, [setContainerWidth, containerRef]);

    const validate = useCallback(() => {
        if (containerRef.current) {
            const inputs = containerRef.current.querySelectorAll('input');

            const correct = Array.from(inputs)
                .reduce((prev, next) => {
                    return prev && next.dataset['correct'] === next.value.toLowerCase();
                }, true)

            if (correct) {
                onComplete()
            }

        }
    }, [containerRef, onComplete])


    useEffect(() => {
        onResize();
        window.addEventListener('resize', onResize);

        return () => {
            window.removeEventListener('resize', onResize);
        }
    }, [onResize]);

    const [showCorrect] = useState(false);

    return <div className="task-06">
        <Box>
            <TaskHeader image={portret} title={TITLE} quote={QUOTE} />
        </Box>
        <Box>
            <div className="task-widget">
                <div className="game-intro">
                    <h2>Zadanie</h2>
                    <p>Konstytucja 3 Maja wprowadzała szereg zmian w ustroju Rzeczypospolitej. Ochrona chłopów czy nadanie praw mieszczanom na zawsze zmieniły polskie społeczeństwo.<br />
                        <strong>
                            Uzupełnij krzyżówkę hasłami związanymi z Konstytucją 3 Maja. Gdy hasło składa się z dwóch słów - zostaw puste pole.
                    </strong>
                    </p>
                </div>
                <ol className="questions">
                    <li>Dynastia, z której mieli wywodzić się kolejni królowie Polski</li>
                    <li>Sposób wyboru władcy, który obowiązywał przed uchwaleniem Konstytucji</li>
                    <li>Władza prawodawcza w Rzeczypospolitej</li>
                    <li>“Wstęp” do Konstytucji</li>
                    <li>Ważna zasada sejmikowa, którą zniosła Konstytucja</li>
                    <li>Król, czyli władza…</li>
                    <li>Jedno z państw tworzących Rzeczpospolitą</li>
                    <li>Osoba stojąca na czele sejmu</li>
                    <li>Co ile miał zbierać się sejm w zwykłych warunkach?</li>
                    <li>Autor obrazu “Konstytucja 3 Maja”</li>
                    <li>Epoka, w której powstała Konstytucja 3 Maja</li>
                </ol>
                <div ref={registerContainer}>

                    <div className="cw-container" ref={containerRef} style={{ height: `${cellSize * crosswordRowCount}px` }}>
                        {
                            crossword.map((field, index) => {

                                return <Cell
                                    key={index}
                                    cellSize={cellSize}
                                    field={field}
                                    inputRef={field.chain ? registerInput(field?.chain) : null}
                                    onChange={validate}
                                    showCorrect={showCorrect}
                                />
                            })
                        }
                    </div>
                </div>
                <TaskButtons id={ID} completed={completed} />
            </div>
        </Box>
    </div>
}

type CellProps = {
    cellSize: number,
    field: CrosswordField,
    inputRef?: any
    onChange: () => void,
    showCorrect: boolean
}

const Cell: React.FC<CellProps> = ({ cellSize, field, inputRef, onChange, showCorrect }) => {
    const borderWidth = 1;

    const [correct, setCorrect] = useState(false);
    const onValueChange = useCallback((e: React.FormEvent<HTMLInputElement>) => {
        setCorrect((e.currentTarget.value[e.currentTarget.value.length - 1] || '').toLowerCase() === field.correct);

        if (onChange) {
            onChange();
        }
    }, [setCorrect, field, onChange]);


    return <div className={`cw-cell cell-${field.type} ${field.extraClass || ''} ${showCorrect ? (correct ? 'correct' : 'wrong') : ''} ${field.marked ? 'marked' : ''}`} style={{
        transform: `translate(${(cellSize) * field.position.x}px, ${(cellSize) * field.position.y}px)`,
        width: `${cellSize - borderWidth}px`,
        height: `${cellSize - borderWidth}px`,
        borderWidth: `${borderWidth}px`
    }}>
        {(field.type === 'label') && <span style={{ fontSize: `${cellSize * .75}px` }}>{field.text}</span>}
        {(field.type === 'input') && field.chain && <input
            type="text"
            data-correct={field.correct}
            ref={inputRef}
            style={{ fontSize: `${cellSize * .75}px` }}
            onFocus={(e: React.FormEvent<HTMLInputElement>) => {
                const target = e.currentTarget;
                setTimeout(() => {
                    target.setSelectionRange(1, 1);
                }, 50)
            }}
            onInput={onValueChange}
        />}
        <div className="pencil-mark">{field.pencil}</div>
    </div>
}

export default Task06Widget;
