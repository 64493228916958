import React, { useCallback, useRef } from "react";
import { DragPreviewImage, useDrag } from "react-dnd";

const EMPTY_IMAGE = new Image();
EMPTY_IMAGE.src = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mNk+M9QDwADhgGAWjR9awAAAABJRU5ErkJggg==';

type Props = {
    data: { type: string, id: string, data: any }
}

const PickUp: React.FunctionComponent<Props> = ({ data, children }) => {
    const ref = useRef<HTMLDivElement>(null);

    const getItem = useCallback(() => {
        return {
            id: data.id,
            type: data.type,
            data: data.data,
            size: {
                width: ref.current?.getBoundingClientRect().width,
                height: ref.current?.getBoundingClientRect().height
            }
        }
    }, [ref, data])

    const [{ isDragging }, drag, preview] = useDrag({
        type: data.type,
        item: getItem,
        collect: (monitor) => {
            return {
                isDragging: monitor.isDragging()
            }
        }
    }, [getItem])

    drag(ref);

    return (<>
        <div className="pick-up" ref={ref} style={{
            opacity: isDragging ? .4 : 1,
            position: "relative",
            cursor: 'grab'
        }}>
            {children}
        </div>
        <DragPreviewImage connect={preview} src={EMPTY_IMAGE.src} />
    </>
    )
}

export default PickUp;


