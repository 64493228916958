import * as PIXI from 'pixi.js';

export class JigsawPiece extends PIXI.Sprite {
    private data?: PIXI.InteractionEvent['data'] | null;
    private dragging = false;
    constructor(texture: PIXI.Texture, public id: number) {
        super(texture);

        this.interactive = true;
        this.buttonMode = true;

        this
            .on('pointerdown', this.onDragStart)
            .on('pointermove', this.onDragMove)
            .on('pointerup', this.onDragEnd)
            .on('pointerupoutside', this.onDragEnd);
    }

    private onDragStart(event: PIXI.InteractionEvent) {
        // store a reference to the data
        // the reason for this is because of multitouch
        // we want to track the movement of this particular touch
        this.data = event.data;
        this.dragging = true;
    }

    private onDragEnd() {
        this.alpha = 1;
        this.dragging = false;
        // set the interaction data to null
        this.data = null;
    }

    private onDragMove() {
        if (this.dragging) {
            const newPosition = this.data?.getLocalPosition(this.parent);
            if (newPosition) {
                this.x = newPosition.x;
                this.y = newPosition.y;
            }
        }
    }
}
