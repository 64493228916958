import { useCallback } from "react"
import { USER_MODULE_ACTION, useUserModuleDispatch } from ".."

export default function useCreateUser() {

    const dispatch = useUserModuleDispatch();

    return useCallback((name: string) => {
        dispatch({
            type: USER_MODULE_ACTION.CREATE_USER,
            payload: {
                name
            }
        })
    }, [dispatch])
}
