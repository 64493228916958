import React, { useMemo } from "react";
import { Link } from "react-router-dom";
import useTasks from "../../modules/tasks/hooks/use-tasks";
import useUser from "../../modules/user/hooks/use-user";
import { getRulesRoute, getTaskRoute } from "../../routes/routes";
import PrettyLink from "../pretty-link";
import './style.scss';

type Props = {}

const GameMap: React.FC<Props> = () => {

    const tasks = useTasks();
    const { user } = useUser();

    const data = useMemo(() => {
        if (tasks && user) {
            return tasks.map(task => {

                const avaiable = task.id !== '10' || (
                    user.completedTasks.includes('01') &&
                    user.completedTasks.includes('02') &&
                    user.completedTasks.includes('03') &&
                    user.completedTasks.includes('04') &&
                    user.completedTasks.includes('05') &&
                    user.completedTasks.includes('06') &&
                    user.completedTasks.includes('07') &&
                    user.completedTasks.includes('08') &&
                    user.completedTasks.includes('09')
                );

                return {
                    task,
                    completed: user?.completedTasks.includes(task.id),
                    avaiable
                }
            })
        }
        return [];
    }, [user, tasks])


    return <>
        {
            data.map(d => {
                if (!d.completed) {
                    if (d.avaiable) {
                        return <Link key={d.task.id} className={`task-link task-link-${d.task.id} avaiable`} to={getTaskRoute(d.task.id)}></Link>
                    } else {
                        return <div key={d.task.id} className={`task-link task-link-${d.task.id}`} ></div>
                    }
                }

                return <React.Fragment key={d.task.id} />
            }
            )
        }
        <div className="buttons">
            <PrettyLink route={getRulesRoute()}>
                Instrukcja
            </PrettyLink>
        </div>
    </>
}

export default GameMap;
