import React, { useCallback, useEffect, useReducer, useState } from "react";
import goraImg from '../../../../assets/task-02/gora.png';
import akademiaImg from '../../../../assets/task-02/akademia.png';
import mostImg from '../../../../assets/task-02/most.png';
import pomnikImg from '../../../../assets/task-02/pomnik.png';
import DropZone from "../../../drag-drop-template/drop-zone";

//@ts-ignore
import { Preview } from 'react-dnd-preview'
import PickUp from "../../../drag-drop-template/pick-up";

type Props = {
    onComplete: () => void;
}

type Item = {
    id: string,
    text: string,
    order: number
}

type Basket = {
    id: string,
    image: string,
    correct: [string, string],
    items: Item[]
}

type State = {
    baskets: Basket[],
    pool: Item[],
}

const Task02Game: React.FC<Props> = ({ onComplete }) => {

    const [state, move] = useReducer((state: State, action: { itemId: string, basketId?: string }) => {

        const item = state.pool.find(i => i.id === action.itemId);
        if (item) {
            return {
                baskets: state.baskets.map(b => {

                    if (b.id === action.basketId) {
                        return {
                            ...b,
                            items: [
                                ...b.items,
                                item
                            ].sort((a, b) => a.order - b.order)
                        }
                    }

                    return b;
                }),
                pool: state.pool.filter(i => i.id !== action.itemId)
            }
        }


        return state;
    }, {
        baskets: [{
            id: 'gora',
            image: goraImg,
            items: [],
            correct: ['gora', 'kosciouszki']
        }, {
            id: 'most',
            image: mostImg,
            items: [],
            correct: ['most', 'poniatowskiego']
        }, {
            id: 'pomnik',
            image: pomnikImg,
            items: [],
            correct: ['pomnik', 'kilinskiego']
        }, {
            id: 'akademia',
            image: akademiaImg,
            items: [],
            correct: ['akademia', 'staszica']
        }],
        pool: [
            { id: 'gora', text: 'Góra', order: 0 },
            { id: 'kosciouszki', text: 'Tadeusza Kościuszki', order: 1 },
            { id: 'most', text: 'Most', order: 0 },
            { id: 'poniatowskiego', text: 'Józefa Poniatowskiego', order: 1 },
            { id: 'pomnik', text: 'Pomnik', order: 0 },
            { id: 'kilinskiego', text: 'Jana Kilińskiego', order: 1 },
            { id: 'akademia', text: 'Akademia Górniczo&nbsp-&nbspHutnicza', order: 0 },
            { id: 'staszica', text: 'Stanisława Staszica', order: 1 },
        ].sort(() => (Math.random() - .5))
    })
    const [wrong, setWrong] = useState<string>('');

    const onDrop = useCallback((pickupData: string, dropId: string) => {

        const spot = state.baskets.find(s => s.id === dropId);

        if (spot && spot.correct.includes(pickupData)) {
            move({
                itemId: pickupData,
                basketId: dropId
            });
        } else {
            setWrong(spot?.id || '');
        }
    }, [state, move, setWrong]);



    useEffect(() => {
        if (wrong) {
            const id = setTimeout(() => {
                setWrong('');
            }, 1000);

            return () => {
                clearTimeout(id);
            }
        }
    }, [wrong, setWrong]);

    useEffect(() => {
        const isCorrect = state.baskets.every(b => {
            if (b.items.length !== 2) return false;

            const correct = b.correct;
            const items = b.items.map(i => i.id);

            return items.includes(correct[0]) && items.includes(correct[0]);
        });

        if (isCorrect) {
            onComplete();
        }

    }, [state, onComplete])

    return <div className="task-02-game">
        <div className="baskets">
            {state.baskets.map(b => {

                const item1 = b.items[0] || null;
                const item2 = b.items[1] || null;

                return <div className={`basket ${wrong === b.id ? 'wrong' : ''}`} key={b.id}>
                    <img src={b.image} alt="" />
                    <DropZone id={b.id} onDrop={onDrop} targetType="item">
                        {item1 && <GameItem item={item1} />}
                        {item2 && <GameItem item={item2} />}
                        {!item1 && <div className="placeholder">przeciągnij tutaj</div>}
                        {!item2 && <div className="placeholder">przeciągnij tutaj</div>}
                    </DropZone>
                </div>
            })}
        </div>
        <div className="item-pool">
            {state.pool.map(item => {
                return <div className="item-in-pool" key={item.id}>
                    <PickUp data={{ type: 'item', id: item.id, data: item }}>
                        <GameItem item={item} />
                    </PickUp>
                </div>
            })}
            {Array(4 - state.pool.length % 4).fill('').map((_, index) => {
                return <div className="item-in-pool" key={index}></div>
            })}
        </div>
        <ItemPreview />
    </div>
}

const GameItem: React.FC<{ item: Item }> = ({ item }) => {
    return <div className="task-02-pick-up">
        <div className="game-item">
            <div className="inner">
                <span dangerouslySetInnerHTML={{ __html: item.text }}></span>
            </div>
        </div>
    </div>
}

const ItemPreview: React.FC<{}> = () => {
    return <Preview>{({ item, style }: any) => {
        return <div style={{ ...style, width: item.size.width }}>
            <GameItem item={item.data} />
        </div>
    }}</Preview>
}



export default Task02Game;
