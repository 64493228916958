
import JigsawGame from "./game";
import { GameState, API } from ".";

type WidgetConfig = {
    container: HTMLElement;
    onPieceMoved?: (index: number, x: number, y: number) => void,
    gameState: GameState,
    onComplete: () => void
}

const BASE_WIDTH = 1778;
const BASE_HEIGHT = 1103;

export default function createJigsaw(config: WidgetConfig): API {
    const app = new JigsawGame(BASE_WIDTH, BASE_HEIGHT, config)

    config.container.appendChild(app.view);

    return {
        movePiece: (id: number, x: number, y: number) => {
            app.movePiece(id, x, y);
        },
        destroy: () => {
            app.destroy();
        }
    };
}
