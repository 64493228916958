import { useCallback } from "react";
import { USER_MODULE_ACTION, useUserModuleDispatch } from "../../user";

export default function useCompleteTask() {
    const dispatch = useUserModuleDispatch();

    return useCallback((taskId: string) => {
        dispatch({
            type: USER_MODULE_ACTION.COMPLETE_TASK,
            payload: {
                taskId
            }
        })
    }, [dispatch])
}

