import React, { useCallback } from "react";
import TaskWidget from "./widget";
import './style.scss';

type Props = {
    id: string
}

const TaskComponent: React.FC<Props> = ({ id }) => {

    const onTaskComplete = useCallback(() => {
    }, [])


    return <div className="task">

        <TaskWidget id={id} onComplete={onTaskComplete} />

    </div>
}

export default TaskComponent;
