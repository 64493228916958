import { useEffect, useMemo, useReducer } from "react"

import img1 from '../../../../assets/task-08/img-1.png';
import img2 from '../../../../assets/task-08/img-2.png';
import img3 from '../../../../assets/task-08/img-3.png';
import img4 from '../../../../assets/task-08/img-4.png';
import img5 from '../../../../assets/task-08/img-5.png';
import img6 from '../../../../assets/task-08/img-6.png';

type Props = {
    onComplete: () => void
}

type Item = {
    id: string,
    image: string,
    options: {
        id: string,
        text: string,
        correct?: boolean,
        selected?: boolean
    }[]
}

type State = Item[];

const Task08Game: React.FC<Props> = ({ onComplete }) => {

    const [state, select] = useReducer((state: State, action: { itemId: string, optionId: string }) => {

        return state.map((item) => {
            if (item.id !== action.itemId) return item;

            return {
                ...item,
                options: item.options.map(opt => {
                    return {
                        ...opt,
                        selected: opt.id === action.optionId
                    }
                })
            }
        })
    }, [
        {
            id: 'i1', image: img1, options: [
                { id: 'o1', text: 'Budka', correct: true },
                { id: 'o2', text: 'Rańtuch' },
                { id: 'o3', text: 'Kołpak' },
            ].sort(() => (Math.random() - .5))
        },
        {
            id: 'i2', image: img2, options: [
                { id: 'o1', text: 'Trikord', correct: true },
                { id: 'o2', text: 'Żupan' },
                { id: 'o3', text: 'Epolety' },
            ].sort(() => (Math.random() - .5))
        },
        {
            id: 'i3', image: img3, options: [
                { id: 'o1', text: 'Gorset', correct: true },
                { id: 'o2', text: 'Krajka' },
                { id: 'o3', text: 'Kontusz' },
            ].sort(() => (Math.random() - .5))
        },
        {
            id: 'i4', image: img4, options: [
                { id: 'o1', text: 'Kontusz', correct: true },
                { id: 'o2', text: 'Jołom' },
                { id: 'o3', text: 'Szarawary' },
            ].sort(() => (Math.random() - .5))
        },
        {
            id: 'i5', image: img5, options: [
                { id: 'o1', text: 'Czepiec' },
                { id: 'o2', text: 'Epolety', correct: true },
                { id: 'o3', text: 'Trikorn' },
            ].sort(() => (Math.random() - .5))
        },
        {
            id: 'i6', image: img6, options: [
                { id: 'o1', text: 'Podwiązka', correct: true },
                { id: 'o2', text: 'Kantusz' },
                { id: 'o3', text: 'Gorset' },
            ].sort(() => (Math.random() - .5))
        },
    ])

    const activeItem = useMemo(() => {
        return state.find(item => {
            return !item.options.every(opt => {
                return !!opt.correct === !!opt.selected;
            })
        })
    }, [state]);

    useEffect(() => {
        const result = state.every(item => {
            return item.options.every(opt => {
                return !!opt.correct === !!opt.selected;
            })
        })

        if (result) {
            onComplete();
        }
    }, [state, onComplete]);

    return <div className="task-08-game">
        {state.map(item => {


            return <div className={`item ${activeItem?.id === item.id ? 'active' : ''}`} key={item.id}>
                <div className="left">
                    <img src={item.image} alt="" />
                </div>
                <div className="right">
                    {item.options.map(opt => {
                        const status = opt.selected ? (opt.correct ? 'correct' : 'wrong') : '';

                        return <div className="option" key={opt.id}>
                            <div className={`item-container ${status}`}>
                                <div className="inner">
                                    <button onClick={() => select({
                                        itemId: item.id,
                                        optionId: opt.id
                                    })}

                                    >{opt.text}</button>

                                </div>
                            </div>
                        </div>
                    })}
                </div>
            </div>
        })}
    </div>
}

export default Task08Game;
