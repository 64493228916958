import React, { useMemo } from "react";
import styled from "styled-components";
import { TileModel } from ".";
import MemoryTile from "./memory-tile";

type Props = {
    gridWidth: number,
    models: TileModel[],
    back: string,
    onTileSelected: (t: number) => void
}

interface GridProps {
    gridWidth: number

}

const Container = styled.div<GridProps>`
    display: flex;
    flex-wrap: wrap-reverse;
    justify-content: space-between;
    padding: 0 0 30px 0;
    & > * {
        width: ${(props) => {return 90 / props.gridWidth}}%;
        margin-bottom: ${(props) => {return 10 / props.gridWidth}}%;
        filter: drop-shadow(0 0 20px rgba(0,0,0,.35));
    }
`

const MemoryGrid: React.FunctionComponent<Props> = ({ gridWidth, models, back, onTileSelected }) => {
    const tiles = useMemo(() => {
        return models.map((m, index) => {
            return (
                <MemoryTile
                    key={index}
                    image={m.image}
                    back={back}
                    isHidden={m.isHidden}
                    isRemoved={m.isRemoved}
                    isMismatched={m.isMismatched}
                    onSelected={() => onTileSelected(index)}
                />
            )
        })

    }, [
        back, models, onTileSelected
    ])

    return (
        <Container
            gridWidth={gridWidth}
        >
            {tiles}
        </Container>
    )
}

export default MemoryGrid;
