
import { useCallback, useState } from 'react';
import './style.scss';

import portret from '../../../../assets/task-08/portret.png';
import React from 'react';
import Box from '../../../../layout/box';
import TaskHeader from '../task-header';
import TaskButtons from '../task-buttons';
import Task08Game from './game';

const ID = '08';
const TITLE = 'Tadeusz Kościuszko';
const QUOTE = 'Jako zaś prawodawstwo sprawowane być nie może przez wszystkich i naród wyręcza się w tej mierze przez reprezentantów czyli posłów swoich dobrowolnie wybranych, przeto stanowimy, iż posłowie na sejmikach obrani w prawodawstwie i ogólnych narodu potrzebach podług niniejszej konstytucji uważani być mają jako reprezentanci całego narodu, będąc składem ufności powszechnej.'

type Props = {
    onComplete?: () => void
}

const Task08Widget: React.FC<Props> = ({ onComplete: completeCallback }) => {

    const [completed, setCompleted] = useState(false);
    const onComplete = useCallback(() => {
        setCompleted(true);
    }, [setCompleted]);

    return <div className="task-08">
        <Box>
            <TaskHeader image={portret} title={TITLE} quote={QUOTE} />
            <div className="persona-info">
                <p>
                    <strong>Tadeusz Kościuszko</strong> - bohater wojny o niepodległość Stanów Zjednoczonych, walczył również w wojnie z Rosją 1792 roku, w której odegrał ważną rolę. Bronił konstytucji 3 maja, gdy król Stanisław August Poniatowski przeszedł na stronę konfederacji targowickiej.<br />W 1794 roku dowodził insurekcją kościuszkowską. Po upadku powstania zmuszony do emigracji, gdzie pomagał tworzyć Legiony Polskie.
            </p>
                <p>W tej chwili uderzyło mnie kilka trawionek zawieszonych na ścianie, których nie spostrzegłem podczas pierwszych tutaj odwiedzin. Były to portrety wielkich mężów historycznych, których życie było nieustannym poświęcaniem się dla wielkich idei ludzkości: Kościuszki, który padł z okrzykiem: „Finis Poloniae!” na ustach.
Jules Verne - 20 000 mil podmorskiej żeglugi.</p>
            </div>
        </Box>
        <Box>
            <div className="task-widget">
                <div className="game-intro">
                    <h2>Zadanie</h2>
                    <p>W ciągu 230 lat, które minęły od uchwalenia Konstytucji nastąpiło wiele zmian. Inaczej jemy, inaczej się myjemy i inaczej się ubieramy. Czy umiesz nazwać XVIII-wieczne ubiory?<br />
                        <strong>

                            Obok każdej ilustracji znajdują się nazwy 3 elementów ubioru. Wybierz za każdym razem 1, który pojawia się na ilustracji.
                    </strong>
                    </p>
                </div>
                <div className={`game-container ${completed ? 'hide' : ''}`}>
                    <Task08Game onComplete={onComplete} />
                </div>
                <TaskButtons id={ID} completed={completed} />
            </div>
        </Box>
    </div>
}

export default Task08Widget;
