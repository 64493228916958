
import React, { useCallback, useState } from 'react';
import Box from '../../../../layout/box';
import TaskHeader from '../task-header';
import './style.scss';
import portret from '../../../../assets/task-04/portret.png';
import TaskButtons from '../task-buttons';
import Task04Game from './game';

const ID = '04';
const TITLE = 'Stanisław Staszic';
const QUOTE = 'chcąc jak najskuteczniej zachęcić pomnożenie ludności krajowej, ogłaszamy wolność zupełną dla wszystkich ludzi, tak nowo przybywających, jak i tych, którzy by, pierwej z kraju oddaliwszy się, teraz do ojczyzny powrócić chcieli.'

type Props = {
    onComplete?: () => void
}

const Task04Widget: React.FC<Props> = ({ onComplete: completeCallback }) => {

    const [completed, setCompleted] = useState(true);
    const onComplete = useCallback(() => {
        setCompleted(true);
    }, [setCompleted]);

    return <div className="task-04">
        <Box>
            <TaskHeader image={portret} title={TITLE} quote={QUOTE} />
            <div className="persona-info">
                <p>
                    <strong>Stanisław Staszic</strong> był księdzem, filozofem i wieloletnim ministrem i wiceministrem. Był pionierem spółdzielczości na ziemiach polskich i działaczem oświeceniowym. Działał przede wszystkim na rzecz chłopów i mieszczan.
                </p>
            </div>
        </Box>
        <Box>
            <div className="task-widget">
                <div className="game-intro">
                    <h2>Zadanie</h2>
                    <p>Pomimo swojej postępowości Konstytucja 3 Maja nie dawała pełnej wolności i praw wyborczych wszystkim mieszkańcom Polski. Kobiety na uzyskanie praw wyborczych musiały czekać do 28 listopada 1918.<br />
                        <strong>
                            Zaproponuj artykuł (lub krótki ustęp), który dodany do konstytucji zapewniałby prawa wyborcze kobietom. Postaraj się zachować podniosły styl i starodawny język.
                    </strong>
                    </p>
                </div>
                <Task04Game onComplete={onComplete} />
                <TaskButtons id={ID} completed={completed} />
            </div>
        </Box>
    </div>
}

export default Task04Widget;
